import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  saveLoginDetails,
  getLoginDetails,
  bankpointLogo,
} from "../../utils/utils";
import { fetchLinkDetails, postJson } from "../../utils/api-client";
import { authInfo } from "../../components/authInfo";
import styles from "./index.module.css";
import { ThemedButton } from "../../components/buttons/themedButton";
import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { startDocuPointLogin } from "./loginHelper";

/**
 * For now, this is a non visible screen which works with the login links mailed out as part of the
 * SendLoginLink flow.
 *
 */
const LoginScreen = () => {
  const history = useHistory();
  const { isAuthenticated } = authInfo;
  const [state, setState] = useState({
    status: "loading",
    emailAddress: "",
    error: false,
    errorMessage: null,
  });
  const [emailAddress, setEmailAddress] = useState(null);

  const onNext = async (emailAddress) => {
    emailAddress = emailAddress ? emailAddress.trim() : "";
    if (emailAddress.length == 0) {
      return;
    }

    setEmailAddress(emailAddress);

    const resp = await fetch(
      `/api/appusers/loginoptions?email=${emailAddress}`
    );

    if (resp.ok) {
      const data = await resp.json();

      if (data.accountActivated) {
        startDocuPointLogin(emailAddress, data.loginConfig, history);
      } else {
        const updates = {
          status: "notActivated",
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    } else {
      const updates = {
        error: true,
        errorMessage: "An error occurred looking up this email address.",
      };

      switch (resp.status) {
        case 404:
          updates.errorMessage =
            "Information provided is not valid. If you are attempting to log in to provide documents which have been requested from you, please use the link that was delivered to you in an email.";
          break;
      }

      setState((prevState) => ({ ...prevState, ...updates }));
    }
  };

  const onInvitationSent = () => {
    const updates = {
      status: "invitationSent",
    };
    setState((prevState) => ({ ...prevState, ...updates }));
  };

  const getLinkDetails = async (val) => {
    try {
      let loginDetails = await fetchLinkDetails(val);
      saveLoginDetails(loginDetails);
      history.push("/login-code");
    } catch (ex) {
      history.push("/tenant-error");
    }
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const hashLink = search.get("link");
    const loginDetails = getLoginDetails();
    //Founk link key in query string
    if (hashLink) {
      getLinkDetails(hashLink);
    }
    //Already logged in
    else if (
      loginDetails &&
      loginDetails.userType == "AppUser" &&
      loginDetails.hasLoggedIn
    ) {
      if (loginDetails.bearer) {
        history.push("/");
      } else {
        startDocuPointLogin(loginDetails.email, loginDetails, history);
      }
    }
    //Not sure where this person is trying to go
    else {
      const updates = {
        status: "enterEmail",
      };
      setState((prevState) => ({ ...prevState, ...updates }));
    }
  }, [history, isAuthenticated]);

  return (
    <div className="flex items-start justify-center py-12 px-4 sm:min-h-screen sm:items-center sm:bg-gray-50 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8 bg-white sm:shadow-lg p-8">
        <img
          className="mx-auto max-h-32 w-auto"
          src={bankpointLogo}
          alt="DocuPoint"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          DocuPoint
        </h2>

        {state.status === "enterEmail" ? (
          <EnterEmail onNext={onNext} />
        ) : state.status == "notActivated" ? (
          <NotActivated
            emailAddress={emailAddress}
            onInvitationSent={onInvitationSent}
          />
        ) : state.status == "invitationSent" ? (
          <InvitationResent />
        ) : (
          ""
        )}

        {state.error && (
          <div className="text-red-600">{state.errorMessage}</div>
        )}
      </div>
    </div>
  );
};

const EnterEmail = ({ onNext }) => {
  const [emailAddress, setEmailAddress] = useState("");

  const handleNext = function (e) {
    e.preventDefault();
    onNext(emailAddress);
  };

  const onChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value);
  };

  return (
    <form onSubmit={handleNext}>
      <div className="mt-8 space-y-6">
        <div className="my-6 text-center text-xl text-gray-700">
          <p>
            Welcome to the DocuPoint portal. Enter an email address to begin.
          </p>
        </div>
        <div className="rounded-md -space-y-px">
          <div>
            <input
              id="code"
              name="code"
              type="text"
              className="w-full"
              value={emailAddress}
              onChange={onChangeEmailAddress}
              autoFocus
            />
          </div>
        </div>
        <div>
          <ThemedButton onClick={handleNext} type="submit">
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon className="h-5 w-5 " aria-hidden="true" />
            </span>
            <span className="text-lg">Next</span>
          </ThemedButton>
        </div>
      </div>
    </form>
  );
};

const NotActivated = ({ emailAddress, onInvitationSent }) => {
  const [errorOccurred, setErrorOccurred] = useState(null);
  const [sending, setSending] = useState(false);

  const handleResend = async () => {
    setSending(true);
    const resp = await fetch(`/api/invitations/resend?email=${emailAddress}`, {
      method: "POST",
    });
    setSending(false);
    if (resp.ok) {
      onInvitationSent();
    } else {
      setErrorOccurred(true);
    }
  };

  return (
    <div>
      <div>
        Your account has not been activated yet. Click the link below to re-send
        an invitation to your email address in order to finish setting up your
        account.
      </div>
      <div className={`mt-2 text-red-600 ${errorOccurred ? "" : "hidden"}`}>
        Sorry, it looks like we're having trouble sending you an invitation.
        Please try again.
      </div>
      <div className="mt-4">
        <ThemedButton onClick={handleResend} type="submit" disabled={sending}>
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <MailIcon className="h-5 w-5 " aria-hidden="true" />
          </span>
          <span className="text-lg">Re-Send Invitation</span>
        </ThemedButton>
      </div>
    </div>
  );
};

const InvitationResent = () => {
  return (
    <div>
      An invitation has been sent to your email address. Please follow the
      instructions in the email finish setting up your account.
    </div>
  );
};

export { LoginScreen };
