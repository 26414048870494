import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { ThemedButton } from "../../components/buttons/themedButton";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { PageError } from "../../components/pageError";
import { CheckIndicator } from "../../components/checkIndicator";
import { KeyIcon, PlusIcon } from "@heroicons/react/outline";
import { KeyIcon as SolidKeyIcon } from "@heroicons/react/solid";
import { ApiKeysModal } from "./apiKeysModal";
import { DocuPointLayout } from "../../components/docupointLayout";

function AppUsersScreen({ global }) {
  const routeMatch = useRouteMatch();
  const baseUrl = routeMatch.url.endsWith("/")
    ? routeMatch.url.substring(0, routeMatch.url.length - 1)
    : routeMatch.url;
  const [state, setState] = useState({
    status: "loading",
    error: null,
    showErrorModal: false,
    showApiKeysModal: false,
    apiKeysUserKey: null,
    appUsers: null,
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const manageApiKeys = function (user) {
    setState((prevState) => ({
      ...prevState,
      showApiKeysModal: true,
      apiKeysUserKey: user.key,
    }));
  };

  useEffect(() => {
    async function loadData() {
      const url = `/api/AppUsers`;
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (response.ok) {
        const data = await response.json();
        const updates = {
          appUsers: data,
          status: "loaded",
        };

        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await response.text();
        console.log(`Error loading app users list: ${message}`);

        let updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }

    if (global) {
    }
    loadData();
  }, []);

  return (
    <Fragment>
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <PageError error={state.error}></PageError>
      ) : (
        <div>
          <div className="pb-4">
            <Link to={`${routeMatch.url}/add`}>
              <ThemedButton fitted={true}>
                <PlusIcon className="w-5 mr-2" />
                Add User
              </ThemedButton>
            </Link>
          </div>
          <table className="dataTable w-full">
            <thead>
              <tr>
                <th className="text-left">Name</th>
                <th className="text-left">Email</th>
                <th>Active</th>
                {!global && <th>Admin</th>}
                <th className="w-32">Api Keys</th>
              </tr>
            </thead>
            <tbody>
              {state.appUsers.map((itm, idx) => {
                return (
                  <UserRow
                    key={itm.key}
                    baseUrl={baseUrl}
                    user={itm}
                    manageAppKeysCallback={manageApiKeys}
                    global={global}
                  />
                );
              })}
            </tbody>
          </table>
          {state.appUsers.length == 0 ? (
            <div className="mt-4 text-gray-500">No users</div>
          ) : (
            ""
          )}
        </div>
      )}

      <ErrorModal
        show={state.showErrorModal}
        onClose={() => setStateValue("showErrorModal", false)}
      ></ErrorModal>
      <ApiKeysModal
        show={state.showApiKeysModal}
        appUserKey={state.apiKeysUserKey}
        onClose={() => setStateValue("showApiKeysModal", false)}
      />
    </Fragment>
  );
}

function UserRow({ baseUrl, user, manageAppKeysCallback, global }) {
  const appKeysClicked = function () {
    manageAppKeysCallback(user);
  };

  return (
    <tr>
      <td className="text-left">
        <a href={`${baseUrl}/${user.key}`} className="colored">
          {user.name}
        </a>
      </td>
      <td className="text-left">{user.email}</td>
      <td>
        <CheckIndicator checked={user.isActive} extraCss="m-auto" />
      </td>
      {!global && (
        <td>
          <CheckIndicator checked={user.tenantAdmin} extraCss="m-auto" />
        </td>
      )}
      <td>
        {user.apiKeysCount > 0 ? (
          <SolidKeyIcon
            className={`h-5 m-auto cursor-pointer text-orange-300`}
            onClick={appKeysClicked}
            title="Manage Api Keys"
          />
        ) : (
          <KeyIcon
            className={`h-5 m-auto cursor-pointer text-gray-500`}
            onClick={appKeysClicked}
            title="Manage Api Keys"
          />
        )}
      </td>
    </tr>
  );
}

export { AppUsersScreen };
