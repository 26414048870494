import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Validator } from "../../../components/validator";
import { useParams, Link } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from "uuid";
import { ThemedButton } from "../../../components/buttons/themedButton";
import { CancelButton } from "../../../components/buttons/themedButton";
import { postJson } from "../../../utils/api-client";
import { authInfo } from "../../../components/authInfo";
import { useHistory } from "react-router-dom";
import { toastError, toastSuccess } from "../../../utils/utils";
import ClickToEditInput from "../../../components/clickToEditInput";
import { PageError } from "../../../components/pageError";

const VIEW_TYPE_EDIT = "edit";
const VIEW_TYPE_ADD = "add";

const STATE_DEFAULT = "";
const STATE_LOADING = "loading"; // Status when the data is loading
const STATE_LOADED = "loaded"; // Status when data is succesfully loaded
const STATE_FAILED = "failed"; // Status when data loading failed
const STATE_SAVING = "saving"; // Status when saving
const STATE_SUCCESS = "success"; // Status when save success
const STATE_SAVE_FAILED = "save failed"; // status when save failed

const TemplateDetailsSchema = Yup.object({
  name: Yup.string().required("Template name is required"),
  documentTypes: Yup.array().min(1, "Enter at least one document"),
});

function DocumentTypes({ form }) {
  const [documentError, setDocumentError] = useState("");

  const handleAddDocumentChange = (event) => {
    const documentTypes = form.values.documentTypes;
    const nameExists = documentTypes.some(
      (d) => d.name.toLowerCase() === event.target.value.toLowerCase()
    );
    if (nameExists) {
      setDocumentError(
        `${event.target.value} already exists in the list of document`
      );
      return;
    } else {
      setDocumentError("");
    }
  };

  const handleAddDocumentKeyPress = async function (event) {
    const documentTypes = form.values.documentTypes;
    if (!!documentError) return;

    switch (event.key) {
      case "Enter":
        const value = event.target.value;
        if (value.length > 0) {
          documentTypes.push({
            key: uuidv4(),
            name: value,
            updateStatus: "Added",
          });
        }
        break;
      case "v":
        if (event.ctrlKey) {
          const content = await navigator.clipboard.readText();
          if (content.indexOf("\n") == -1) {
            return;
          }
          const lines = content.split("\n");
          lines.forEach((l) => {
            l = l.trim();
            if (l.length > 0) {
              documentTypes.push({
                key: uuidv4(),
                name: l,
                updateStatus: "Added",
              });
            }
          });
        } else {
          return true;
        }
        break;
      default:
        return true;
    }

    form.setFieldValue("documentTypes", documentTypes);
    event.target.value = "";
  };

  const onRemoveDocument = function (keyToRemove) {
    const documentTypes = form.values.documentTypes.map((documentType) => {
      if (documentType.key === keyToRemove) {
        return {
          ...documentType,
          updateStatus: "Removed",
        };
      } else {
        return { ...documentType };
      }
    });
    form.setValues((values) => ({ ...values, documentTypes }));
  };

  const updateDocTypeName = (docTypeKey, value) => {
    const currentDocumentTypes = form.values.documentTypes;

    const documentTypes = currentDocumentTypes.map((d) => {
      if (d.key !== docTypeKey) return { ...d };
      d.name = value;
      if (d.updateStatus !== "Added") {
        d.updateStatus = "Updated";
      }

      return { ...d };
    });

    form.setFieldValue("documentTypes", documentTypes);
  };

  return (
    <div className="flex-auto">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="documentType" className="formLabel">
            Document Types
          </label>
          <div>
            {form.values.documentTypes?.map((itm) => {
              return (
                itm.updateStatus !== "Removed" && (
                  <div
                    className="flex group items-center mt-1 p-1 text-gray-600"
                    key={itm.key}
                  >
                    <ClickToEditInput
                      value={itm.name}
                      onChange={(e) =>
                        updateDocTypeName(itm.key, e.target.value)
                      }
                    />
                    <div
                      className="opacity-0 actionButton group-hover:opacity-100"
                      style={{ padding: "3px", marginTop: "1px" }}
                      onClick={() => onRemoveDocument(itm.key)}
                    >
                      <XIcon />
                    </div>
                  </div>
                )
              );
            })}
          </div>
          <input
            type="text"
            placeholder="Add a document name here and press enter"
            onKeyDown={handleAddDocumentKeyPress}
            onChange={handleAddDocumentChange}
          />
          <Validator
            fieldName="documentTypes"
            errorsList={form.errors}
          ></Validator>
          <Validator
            fieldName="documentTypes"
            errorsList={{ documentTypes: documentError }}
          ></Validator>
        </div>
      </div>
    </div>
  );
}

function TemplateDetails() {
  const [header, setHeader] = useState("");
  const [viewType, setViewType] = useState(VIEW_TYPE_ADD);
  const [state, setState] = useState(STATE_DEFAULT);
  const [error, setError] = useState("");
  const { templateKey } = useParams();
  const history = useHistory();

  const form = useFormik({
    initialValues: {
      key: "",
      name: "",
      documentTypes: [],
    },
    validationSchema: TemplateDetailsSchema,
    onSubmit: async (formData) => {
      if (viewType === VIEW_TYPE_ADD) {
        await addTemplate(formData);
      } else {
        await updateTemplate(formData);
      }
    },
  });

  const addTemplate = async (data) => {
    setState(STATE_SAVING);
    try {
      const url = "/api/documentrequesttemplates/create";
      const result = await postJson(url, data);
      setState(STATE_SUCCESS);
      if (result.status >= 400) throw "Error in adding document template";
    } catch (err) {
      setError(err);
      setState(STATE_SAVE_FAILED);
    }
  };

  const updateTemplate = async (data) => {
    setState(STATE_SAVING);
    try {
      const url = `/api/documentrequesttemplates/${data.key}`;
      const result = await postJson(url, data);

      if (result.status >= 400) throw "Error in updating document template";
      setState(STATE_SUCCESS);
    } catch (err) {
      setError(err);
      setState(STATE_SAVE_FAILED);
    }
  };

  const loadTemplateDetails = async () => {
    try {
      const url = `/api/documentrequesttemplates/${templateKey}`;
      const resp = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (resp.status >= 400) throw "Error in loading document details";
      const data = await resp.json();

      form.setValues({ ...data });
      setHeader(data.name);
      setState(STATE_LOADED);
    } catch (err) {
      setError(err);
      setState(STATE_FAILED);
    }
  };

  useEffect(() => {
    if (!templateKey) {
      setHeader("New Checklist");
      setViewType(VIEW_TYPE_ADD);
    } else {
      setViewType(VIEW_TYPE_EDIT);
      setState(STATE_LOADING);
      loadTemplateDetails();
    }
  }, []);

  useEffect(() => {
    if (state === STATE_SUCCESS) {
      if (viewType === VIEW_TYPE_ADD) {
        history.push("/templates");
      } else if (viewType === VIEW_TYPE_EDIT) {
        loadTemplateDetails();
        toastSuccess("Template has been updated");
      }
    } else if (state === STATE_SAVE_FAILED) {
      toastError(error);
    }
  }, [state]);

  if (state === STATE_FAILED) {
    return <PageError error={{ message: error }} />;
  }

  return (
    <>
      <form>
        <div className="flex items-center text-l rounded pt-1 mb-1">
          <div className="flex-none">{header}</div>
        </div>
        <div className="bg-gray-100 p-4">
          <div className="formSection">
            <div className="formSectionLeft">
              <div className="px-4 sm:px-0">
                <h3 className="formSectionHeader">General</h3>
              </div>
            </div>
            <div className="formSectionRight">
              <div className="formSectionRightPanel">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="name" className="formLabel">
                      Name
                    </label>
                    <input
                      type="text"
                      value={form.values.name}
                      name="name"
                      placeholder="Enter a checklist name"
                      onChange={form.handleChange}
                    />
                    <Validator
                      fieldName="name"
                      errorsList={form.errors}
                    ></Validator>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="formSection mt-4">
            <div className="formSectionLeft">
              <div className="px-4 sm:px-0">
                <h3 className="formSectionHeader">Document Types</h3>
              </div>
            </div>
            <div className="formSectionRight">
              <div className="formSectionRightPanel">
                <DocumentTypes form={form} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="mt-4">
        <div className="inline-block w-40">
          <ThemedButton
            onClick={form.handleSubmit}
            disabled={state === STATE_LOADING || !form.isValid}
          >
            Save
          </ThemedButton>
        </div>
        <div className="inline-block w-40 ml-2">
          <Link to="/templates">
            <CancelButton>Cancel</CancelButton>
          </Link>
        </div>
      </div>
    </>
  );
}

export { TemplateDetails };
