import React, {useState, useEffect} from 'react';
import {authInfo} from "../components/authInfo";

const Auth0HistoryProvider = ({children}) => {
    const [state, setState] = useState({initialized: false});
    
    useEffect(()=> {
        const getAuthConfig = async() => {
            let resp = await fetch('/authinfo');
            let data = await resp.json();
            setState({initialized: true, ...data});
            
            authInfo.audience = data.audience;
            authInfo.authority = data.authority;
            authInfo.clientId = data.clientId;
            authInfo.domain = data.domain;
        }

        getAuthConfig();
    }, []);
    
    const {initialized} = state;

    return initialized ?
        <div>    
        {
            children
        }
        </div>
        : 
        <div>&nbsp;</div>
};

export default Auth0HistoryProvider;

