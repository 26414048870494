import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { getLoginDetails, saveLoginDetails } from "../../utils/utils.js";

function GlobalRoute({ children }) {
  const loginDetails = getLoginDetails();
  loginDetails.tenantInfo = null;
  saveLoginDetails(loginDetails);

  return <Fragment>{children}</Fragment>;
}

export { GlobalRoute };
