import React, { useState, useEffect, Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import { Loading } from "./loading";
import { authInfo, getWebAuth } from "../components/authInfo";
import { useHistory } from "react-router-dom";
import {
  getLoginDetails,
  getProfile,
  getRoles,
  saveLoginDetails,
} from "../utils/utils";
import { InactiveUserScreen } from "../screens/login/inactiveUserScreen";
import { DocuPointLayout } from "./docupointLayout";
import * as Constants from "../constants";

const ProtectedRoute = ({
  children,
  loginPath = "/login",
  requireActiveAccount = true,
  requiredRoles = null,
  ...rest
}) => {
  const loginDetails = getLoginDetails();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthorized, setAuthorized] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  async function loadData() {
    const webAuth = getWebAuth();
    webAuth.checkSession(
      {
        audience: authInfo.audience,
        scope: "openid profile email",
      },
      function (err, authResult) {
        if (err) {
          if (loginDetails) {
            loginDetails.bearer = null;
            saveLoginDetails(loginDetails);
          }
          console.log(`checkSession errored out with: ${err.code}`);
          // redirect to the login page if they are not authenticated
          history.push(loginPath);
        } else {
          loginDetails.bearer = authResult.accessToken;
          saveLoginDetails(loginDetails);
          setAuthenticated(true);
          checkAccountStatus();
        }
      }
    );
  }

  const checkAccountStatus = async () => {
    const roles = await getRoles(true);
    setIsActiveUser(roles.indexOf(Constants.ROLES_ACTIVE_USER) >= 0);

    if (requiredRoles?.length > 0) {
      const authorized =
        requiredRoles.filter((x) => roles.indexOf(x) == -1).length == 0;
      setAuthorized(authorized);
    } else {
      setAuthorized(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!loginDetails) {
      history.push(loginPath);
      return;
    }
    loadData();
  }, []);

  return (
    <Fragment>
      {isLoading && <Loading />}

      {!isLoading && (
        <Route
          {...rest}
          render={() => {
            if (!isAuthenticated) {
              return <Redirect to={loginPath ? loginPath : "login"} />;
            }

            if (!isActiveUser && requireActiveAccount) {
              return <InactiveUserScreen />;
            }

            if (!isAuthorized) {
              return <DocuPointLayout unauthorized={true} />;
            }

            return children;
          }}
        />
      )}
    </Fragment>
  );
};

export { ProtectedRoute };
