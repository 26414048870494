import React, { useEffect, useRef, useState } from "react";

function ClickToEditInput({ value, onChange }) {
  const [isEditable, setIsEditable] = useState(false);
  const elementRef = useRef();

  const handleOnInputBlur = () => {
    // Added delay to rerender the component first before updating the component
    setTimeout(() => {
      setIsEditable(false);
    }, [200]);
  };
  useEffect(() => {
    elementRef.current?.focus();
  }, [isEditable]);

  if (isEditable) {
    return (
      <input
        type="text"
        className="w-auto"
        value={value}
        onChange={onChange}
        ref={elementRef}
        onBlur={handleOnInputBlur}
      ></input>
    );
  } else {
    return <div onClick={() => setIsEditable(true)}>{value}</div>;
  }
}

export default ClickToEditInput;
