import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import { createRoot } from "react-dom/client";
import Auth0HistoryProvider from "./components/auth0HistoryProvider";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename={baseUrl}>
    <Auth0HistoryProvider>
      <App />
    </Auth0HistoryProvider>
  </BrowserRouter>
);
