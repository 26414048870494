import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { bankpointLogo, toastSuccess } from "../../utils/utils";
import { authInfo } from "../../components/authInfo";
import { ThemedButton } from "../../components/buttons/themedButton";
import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { startDatabaseLogin } from "./loginHelper";
import { Validator } from "../../components/validator";

/**
 * For now, this is a non visible screen which works with the login links mailed out as part of the
 * SendLoginLink flow.
 *
 */
const SetPasswordScreen = () => {
  const history = useHistory();
  const loginState = history.location.state;
  const { isAuthenticated } = authInfo;
  const [state, setState] = useState({
    status: "ready",
    validationErrors: {},
    fields: {
      password: "",
      confirmPassword: "",
    },
    error: false,
    errorMessage: null,
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = function (event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const fields = state.fields;
    fields[name] = value;
    setStateValue("fields", fields);
  };

  const handleSetPassword = async () => {
    const data = {
      password: state.fields.password,
    };

    let headers = {
      ...{ "Content-Type": "application/json" },
    };

    let url = "/api/profile/password";
    let method = "PUT";

    if (loginState) {
      url = "/api/identities/initializepassword";
      method = "POST";
      data.appUserKey = loginState.appUserKey;
    } else {
      const authenticationHeaders = authInfo.getAuthenticationHeaders();
      headers = {
        ...headers,
        ...authenticationHeaders,
      };
    }
    const resp = await fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(data),
    });

    if (resp.ok) {
      const updates = {
        status: "passwordChanged",
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    } else {
      const updates = {
        error: true,
        errorMessage: "An error occurred setting your password.",
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    }
  };

  const handleLogin = () => {
    const loginStateJson = loginState ? JSON.stringify(loginState) : null;
    startDatabaseLogin(loginStateJson);
  };

  useEffect(() => {}, []);

  return (
    <div className="flex items-start justify-center py-12 px-4 sm:min-h-screen sm:items-center sm:bg-gray-50 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8 bg-white sm:shadow-lg p-8">
        <img
          className="mx-auto max-h-32 w-auto"
          src={bankpointLogo}
          alt="DocuPoint"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          DocuPoint
        </h2>

        {state.status == "ready" && (
          <div className="mt-8 space-y-6">
            <div className="my-6 text-center text-xl text-gray-700">
              <p>Set your initial password to continue.</p>
            </div>
            <div className="rounded-md -space-y-px">
              <div className="grid grid-cols-1 gap-6 w-64 m-auto">
                <div>
                  <label htmlFor="name" className="formLabel">
                    Password
                  </label>
                  <input
                    type="password"
                    value={state.fields.password}
                    name="password"
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                  />
                  <Validator
                    fieldName="password"
                    errorsList={state.validationErrors}
                  ></Validator>
                </div>
                <div>
                  <label htmlFor="name" className="formLabel">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    value={state.fields.confirmPassword}
                    name="confirmPassword"
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                  />
                  <Validator
                    fieldName="confirmPassword"
                    errorsList={state.validationErrors}
                  ></Validator>
                </div>
                <div>
                  <ThemedButton onClick={handleSetPassword} type="submit">
                    Next
                  </ThemedButton>
                </div>
              </div>
            </div>
          </div>
        )}

        {state.status == "passwordChanged" && (
          <div className="mt-8 space-y-6">
            <div className="my-6 text-center text-xl text-gray-700">
              <p>
                Your password was successfully changed. Please log in to
                continue.
              </p>
            </div>
            <div>
              <ThemedButton onClick={handleLogin}>
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 " aria-hidden="true" />
                </span>
                <span>Login</span>
              </ThemedButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { SetPasswordScreen };
