import React from 'react';
import styles from './index.module.css'

function DropDown({ name, options, value, onChange }) {
    return (
        <select value={value} name={name} onChange={onChange}>
            {
                options.map((itm, idx) => {
                    return <option key={itm.id} value={itm.id}>{itm.name}</option>
                })
            }
        </select>
    )
};

export { DropDown };

