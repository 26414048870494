import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { DocuPointLayout } from "../../components/docupointLayout.js";
import { TenantHeader } from "./tenantHeader";
import { AppUsersScreen } from "../users/appUsersScreen.js";

function TenantUsersScreen() {
  return (
    <DocuPointLayout>
      <TenantHeader location="Users" />
      <AppUsersScreen global={false} />
    </DocuPointLayout>
  );
}

export { TenantUsersScreen };
