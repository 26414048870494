import { CheckIcon } from '@heroicons/react/outline'
import React, { Fragment } from 'react'
import styles from './index.module.css'

function CheckIndicator({ checked, extraCss }) {
    return (
        <Fragment>
            {
                checked ? <CheckIcon className={`${styles.checkIndicator} ${extraCss}`}></CheckIcon> : ''
            }
        </Fragment>
    )
}

export { CheckIndicator }