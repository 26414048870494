import React from "react";
import { logout, bankpointLogo } from "../../utils/utils";
import { ThemedButton } from "../../components/buttons/themedButton";

const InactiveUserScreen = () => {
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="flex items-start justify-center py-12 px-4 sm:min-h-screen sm:items-center sm:bg-gray-50 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8 bg-white sm:shadow-lg p-8">
        <img
          className="mx-auto max-h-32 w-auto"
          src={bankpointLogo}
          alt="DocuPoint"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          DocuPoint
        </h2>

        <div>
          <div className={`mt-2 text-red-600`}>
            Sorry, your account is not active. Please log in with a different
            account, or contact your administrator.
          </div>
          <div className="mt-4">
            <ThemedButton onClick={handleLogout}>
              <span className="text-lg">Log out</span>
            </ThemedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export { InactiveUserScreen };
