import React, { Fragment } from 'react'

function Paragraphs({ text }) {
    const paragraphs = text.split('\n');
    return (
        <Fragment>
            {
                paragraphs.map(function (item, idx) {
                    return (
                        <p key={`paragraph_${idx}`} className={idx > 0 ? "mt-2" : ""}>{item}</p>
                    )
                })
            }        </Fragment>
    )
}

export { Paragraphs }