import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import { getLoginDetails } from "../../utils/utils.js";

const ThemedButtonBase = ({
  children,
  extraClass,
  settings = null,
  fitted = false,
  disabled = false,
  ...rest
}) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    if (disabled) {
      return;
    }
    setHover(true);
  };

  const onLeaveHover = () => {
    if (disabled) {
      return;
    }
    setHover(false);
  };

  if (settings == null) {
    const loginDetails = getLoginDetails();
    if (loginDetails && loginDetails.tenantInfo) {
      settings = loginDetails.tenantInfo;
    } else {
      settings = {
        buttonColor: "#333333",
        buttonFocusColor: "#000000",
        buttonHoverColor: "#666666",
      };
    }
  }

  const backgroundColor = settings.buttonColor;
  const focusColor = settings.buttonFocusColor;

  return (
    <button
      className={`${styles.btnBase} ${extraClass} ${
        fitted ? styles.fitted : ""
      }`}
      style={{
        backgroundColor: backgroundColor,
        "--tw-ring-color": backgroundColor,
        color: "#FFFFFF",
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeaveHover}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

/**
 * Tenant themed button with circle flair
 */
const ThemedCircleButton = ({ children, ...rest }) => {
  return (
    <ThemedButtonBase extraClass={styles.btnRound} {...rest}>
      {children}
    </ThemedButtonBase>
  );
};

/**
 * Button component with tenant themed colors
 */
const ThemedButton = ({ children, settings, extraClass, fitted, ...rest }) => {
  return (
    <ThemedButtonBase
      extraClass={`${styles.btnStandard} ${extraClass} ${
        fitted ? styles.fitted : ""
      }`}
      settings={settings}
      {...rest}
    >
      {children}
    </ThemedButtonBase>
  );
};

const CancelButton = ({ children, fitted = false, ...rest }) => {
  return (
    <button
      type="button"
      className={`${styles.btnCancel} ${fitted ? styles.fitted : ""}`}
      {...rest}
    >
      {children ? children : "Cancel"}
    </button>
  );
};

export { ThemedButton, ThemedCircleButton, CancelButton };
