import React from "react";

function EmptyListPlaceholder({message}){
    return (
        <div>
            {message}
        </div>
    )
}

export {EmptyListPlaceholder}