// Either:
// 1) an AppUser (admin or document requestor) with an activated profile
// 2) an Account (document requestee)
export const ROLES_ACTIVE_USER = "ActiveUser";

// An authenticated user on the DocuPoint+ side
export const ROLES_APP_USER = "AppUser";

// An authenticated user who has received a document request invitation
export const ROLES_ACCOUNT = "Account";

// An AppUser who has been created as a Global Admin
export const ROLES_GLOBAL_ADMIN = "GlobalAdmin";

// An AppUser who has been granted the Tenant Admin permission
export const ROLES_TENANT_ADMIN = "TenantAdmin";

// An AppUser who can request documents and manage document requests. All active AppUsers receive this role.
export const ROLES_TENANT_EDIT = "TenantEdit";

// An AppUser who can view requests and other data within a tenant. All active AppUsers receive this role.
export const ROLES_TENANT_VIEW = "TenantView";
