import React from 'react';
import styles from './index.module.css'

function ToggleButton({ checked, onChange }) {
    const handleClick = function (e) {
        onChange();
        e.preventDefault();
    }
    const handleCheckboxChange = function () { }

    return (
        <div className={`${styles.toggleButton} inline-block`}>
            <label className="flex items-center cursor-pointer" onClick={handleClick}>
                {/* toggle */}
                <div className="relative">
                    {/* input */}
                    <input type="checkbox" className="sr-only" checked={checked} onChange={handleCheckboxChange} />
                    {/* line */}
                    <div className={`${styles.line} w-10 h-4 bg-gray-200 rounded-full shadow-inner`}></div>
                    {/* dot */}
                    <div className={`${styles.dot} absolute border border-gray-100 w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition`}></div>
                </div>
            </label>
        </div>
    )
};

export { ToggleButton };

