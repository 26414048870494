import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { bankpointLogo, getLoginDetails } from "../../utils/utils.js";
import styles from "./index.module.css";

const TenantHeader = ({ location }) => {
  const { tenantInfo } = getLoginDetails();
  const links = [
    { name: "Settings", path: `/settings` },
    { name: "Users", path: `/users` },
    { name: "Checklists", path: `/templates` },
  ];
  return (
    <div>
      <div className="flex mb-4">
        <div className="flex-none text-xl">
          {tenantInfo?.displayTenantName ? tenantInfo.name : ""} Admin
        </div>
        <div className="flex-1 text-right mt-1">
          <ul className={styles.subNavigationMenu}>
            {links.map((itm, idx) => {
              return (
                <Fragment key={itm.name}>
                  <li
                    className={location == itm.name ? `${styles.selected}` : ""}
                  >
                    <Link to={itm.path}>{itm.name}</Link>
                  </li>
                </Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { TenantHeader };
