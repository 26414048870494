import React, { useEffect, useState, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  saveLoginDetails,
  startPasswordlessLogin,
  startUniversalLogin,
} from "../../utils/utils";
import { authInfo } from "../../components/authInfo";

/**
 * For now, this is a non visible screen which works with the login links mailed out as part of the
 * SendLoginLink flow.
 *
 */
const AppLoginScreen = () => {
  const history = useHistory();
  const { isAuthenticated } = authInfo;

  const doUniversalLogin = async () => {
    const loginDetails = {
      userType: "AppUser",
      accountKey: "AppUser",
    };

    saveLoginDetails(loginDetails);
    startUniversalLogin();
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/admin");
    }
    // link values override a saved tenant key
    doUniversalLogin();
  }, [history, isAuthenticated]);

  return <div></div>;
};

export { AppLoginScreen };
