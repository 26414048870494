import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { DocuPointLayout } from "../../components/docupointLayout.js";
import { getLoginDetails, saveLoginDetails } from "../../utils/utils.js";
import { AppUserScreen } from "../users/appUserScreen.js";
import { TenantHeader } from "./tenantHeader.js";

function TenantAppUserScreen() {
  const { appUserKey } = useParams();

  return (
    <DocuPointLayout>
      <TenantHeader location="Users" />
      <AppUserScreen returnTo="/users" appUserKey={appUserKey} global={false} />
    </DocuPointLayout>
  );
}

export { TenantAppUserScreen };
