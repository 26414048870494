import React, { createContext, useEffect, useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { TenantRoute } from "../../components/tenantRoute";
import AccountListScreen from "./accountsListScreen";
import { AccountScreen } from "./accountScreen";
import { ProtectedRoute } from "../../components/protectedRoute";
import * as Constants from "../../constants";

function AccountsIndex() {
  const match = useRouteMatch();

  const [state, setState] = useState({
    status: "loading",
  });

  useEffect(() => {
    async function loadData() {
      const updates = {
        status: "loaded",
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    }
    loadData();
  }, []);

  return (
    <TenantRoute>
      {state.status === "loaded" ? (
        <Switch>
          <ProtectedRoute
            path={`${match.path}/:account`}
            requiredRoles={[Constants.ROLES_TENANT_VIEW]}
          >
            <AccountScreen />
          </ProtectedRoute>
          <ProtectedRoute
            path={match.path}
            requiredRoles={[Constants.ROLES_TENANT_VIEW]}
          >
            <AccountListScreen />
          </ProtectedRoute>
        </Switch>
      ) : state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div className="flex justify-center">
          <ErrorFallback error={state.error} />
        </div>
      ) : (
        ""
      )}
    </TenantRoute>
  );
}

export { AccountsIndex };
