import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { ThemedButton } from "../../../components/buttons/themedButton";
import { authInfo } from "../../../components/authInfo";
import { Placeholder } from "../../../components/placeholder";
import { PageError } from "../../../components/pageError";

function TemplateList() {
  const [customerRequestTemplates, setCustomerRequestTemplates] =
    useState(null);
  const [state, setState] = useState("loading");
  const [error, setError] = useState("");
  const { url } = useRouteMatch();

  const getTemplateList = async () => {
    try {
      const url = "api/documentrequesttemplates";
      const resp = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (resp.status >= 400) throw "Error in getting template list";
      const data = await resp.json();
      setCustomerRequestTemplates(data);
      setState("loaded");
    } catch (err) {
      setState("failed");
      setError(err);
    }
  };

  useEffect(() => {
    getTemplateList();
  }, []);

  if (state === "loading") {
    return <Placeholder />;
  }

  if (state === "failed") {
    return <PageError error={{ message: error }} />;
  }

  return (
    <>
      <Link to={`${url}/add`} className="pb-4">
        <ThemedButton fitted={true}>
          <PlusIcon className="w-5 mr-2" />
          Add Checklist
        </ThemedButton>
      </Link>
      <div>
        {!!customerRequestTemplates ? (
          <table className="dataTable w-full">
            <thead>
              <tr>
                <th className="text-left">Name</th>
              </tr>
            </thead>
            <tbody>
              {customerRequestTemplates.map((template) => (
                <tr key={template.key}>
                  <td>
                    <Link to={`/templates/${template.key}`}>
                      {template.name}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <span className="text-sm">
              Template is currently empty, click Add Template to create new
            </span>
          </div>
        )}
      </div>
    </>
  );
}

export { TemplateList };
