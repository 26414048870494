import React, { Fragment, useState, useEffect } from "react";
import { authInfo } from "../../components/authInfo";
import { postJson } from "../../utils/api-client";
import { toastSuccess, toastError } from "../../utils/utils.js";
import { ErrorFallback } from "../../components/errorFallback";
import { Placeholder } from "../../components/placeholder";
import {
  ThemedButton,
  CancelButton,
} from "../../components/buttons/themedButton";
import { Modal } from "../../components/modal";
import { XIcon } from "@heroicons/react/solid";
import { PlusIcon } from "@heroicons/react/outline";

function ApiKeysModal({ show, appUserKey, onClose }) {
  const [state, setState] = useState({
    status: "loading",
    error: null,
    saving: false,
    apiKeys: [],
    hasNewKey: false,
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addApiKey = async function () {
    setStateValue("saving", true);
    let url = `/api/appUsers/${appUserKey}/apikeys`;
    const resp = await postJson(url, {});
    setStateValue("saving", false);

    if (resp.ok) {
      toastSuccess("Api Key created!");
      const apiKeys = state.apiKeys;
      const newKey = await resp.json();
      apiKeys.push(newKey);
      const updates = {
        apiKeys: apiKeys,
        hasNewKey: true,
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    } else {
      toastError("An error occurred creating an Api Key");
    }
  };

  const deleteApiKey = async function (apiKey) {
    setStateValue("saving", true);
    let url = `/api/appUsers/${appUserKey}/apikeys/${apiKey}`;
    const resp = await postJson(url, {}, "DELETE");
    setStateValue("saving", false);

    if (resp.ok) {
      toastSuccess("Api Key deleted!");
      loadData();
    } else {
      toastError("An error occurred creating an Api Key");
    }
  };

  async function loadData() {
    setStateValue("status", "loading");

    const url = `/api/AppUsers/${appUserKey}/apikeys`;
    const response = await fetch(url, {
      headers: authInfo.getAuthenticationHeaders(),
    });

    if (response.ok) {
      const data = await response.json();
      const updates = {
        apiKeys: data,
        status: "loaded",
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    } else {
      const message = await response.text();
      console.log(`Error loading app user data: ${message}`);

      const updates = {
        status: "error",
        error: new Error(
          "We're sorry. There seems to be an issue loading this page."
        ),
      };
      setState((prevState) => ({ ...prevState, ...updates }));
    }
  }

  useEffect(() => {
    if (!show) {
      return;
    }

    loadData();
  }, [show]);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="text-xl text-center font-bold pb-5 mb-5 border-b border-gray-200">
        Api Keys
      </div>

      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div className="flex justify-center">
          <ErrorFallback error={state.error} />
        </div>
      ) : (
        <div>
          <CancelButton fitted={true} onClick={addApiKey}>
            <PlusIcon className="w-5 mr-2" />
            Add Key
          </CancelButton>

          <div
            className={`${
              state.hasNewKey ? "" : "hidden"
            } modalInstructions mt-4`}
          >
            Please copy and protect the key below. This is the only time it will
            be displayed in this admin portal.
          </div>

          <table className="dataTable w-full">
            <thead>
              <tr>
                <th>Key</th>
                <th className="text-center">Expires</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.apiKeys.map((itm, idx) => {
                return (
                  <ApiKeyRow
                    key={itm.key}
                    apiKey={itm}
                    onDelete={deleteApiKey}
                  />
                );
              })}
            </tbody>
          </table>
          {state.apiKeys.length == 0 ? (
            <div className="mt-4 text-gray-500">No Api Keys</div>
          ) : (
            ""
          )}
        </div>
      )}

      <div className="mt-8 flex">
        <ThemedButton onClick={onClose}>Close</ThemedButton>
      </div>
    </Modal>
  );
}

function ApiKeyRow({ apiKey, onDelete }) {
  const copyKey = () => {
    navigator.clipboard.writeText(apiKey.fullApiKey);
    toastSuccess("Copied");
  };

  const handleDelete = () => {
    onDelete(apiKey.id);
  };

  return (
    <tr>
      <td className="text-left">
        <span className={`${apiKey.fullApiKey == null ? "" : "hidden"}`}>
          {apiKey.keyPrefix}...
        </span>
        <span
          className={`${
            apiKey.fullApiKey != null ? "" : "hidden"
          } cursor-pointer hover:bg-gray-100`}
          onClick={copyKey}
          title="Copy Key"
        >
          {apiKey.fullApiKey}
        </span>
      </td>
      <td className="text-center">
        {new Date(apiKey.expirationDateUtc).toLocaleDateString()}
      </td>
      <td>
        <XIcon
          className="text-gray-500 h-5 cursor-pointer"
          onClick={handleDelete}
          title="Delete"
        />
      </td>
    </tr>
  );
}

export { ApiKeysModal };
