import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  CancelButton,
  ThemedButton,
} from "../../components/buttons/themedButton";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { RefreshIcon, XIcon, CheckIcon } from "@heroicons/react/solid";
import styles from "./index.module.css";
import { Validator } from "../../components/validator";
import { DocuPointLayout } from "../../components/docupointLayout";
import { FieldArray, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { postJson } from "../../utils/api-client";
import {
  toastError,
  toastSuccess,
  isResponseUnauthorized,
  valueOrEmpty,
} from "../../utils/utils";
import {
  BreadCrumb_Requests_Request,
  BreadCrumb_Requests_Create,
} from "../../components/breadcrumb";
import ClickToEditInput from "../../components/clickToEditInput";
import { Combobox } from "@headlessui/react";

function RequestInformation(props) {
  const { form } = props;

  return (
    <>
      <div className="formSectionLeft">
        <div className="px-4 sm:px-0">
          <h3 className="formSectionHeader">Request Information</h3>
          <p className="formSectionHeaderDescription">
            Basic information about this request.
          </p>
        </div>
      </div>
      <div className="formSectionRight">
        <div className="formSectionRightPanel">
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3 sm:col-span-2">
              <label htmlFor="requestName" className="formLabel">
                Request Name*
              </label>
              <p className="formHelpText">
                e.g. Clearwater LLC Loan Application
              </p>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  id="requestName"
                  type="text"
                  value={form.values.requestName}
                  name="requestName"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />{" "}
                {form.touched.requestName && (
                  <Validator
                    fieldName="requestName"
                    errorsList={form.errors}
                  ></Validator>
                )}
              </div>
            </div>
            <div className="col-span-3 sm:col-span-2">
              <label htmlFor="account" className="formLabel">
                Account
              </label>
              <p className="formHelpText">
                An account identifier that associates this request with related
                requests.
              </p>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  id="account"
                  type="text"
                  value={form.values.account}
                  name="account"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />{" "}
                {form.touched.account && (
                  <Validator
                    fieldName="account"
                    errorsList={form.errors}
                  ></Validator>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RecipientInformations({ form }) {
  const [accounts, setAccounts] = useState();

  const getAccounts = async () => {
    const url = `/api/accounts`;
    const resp = await fetch(url, {
      headers: authInfo.getAuthenticationHeaders(),
    });
    setAccounts(await resp.json());
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <>
      <div className="formSectionLeft">
        <div className="px-4 sm:px-0">
          <h3 className="formSectionHeader">Recipient Information</h3>
          <p className="formSectionHeaderDescription">
            Who will provide the requested documents?
          </p>
        </div>
      </div>
      <div className="formSectionRight">
        {form?.values?.accounts && (
          <FieldArray
            name="accounts"
            render={(formArray) => (
              <>
                {form.values.accounts.map((account, index) => (
                  <Fragment>
                    <div className="formSectionRightPanel mb-2 last:mb-0">
                      <div
                        key={account.key}
                        className="my-3 pt-2 first:mt-0 first:pt-0"
                      >
                        <div className="mb-2 flex flex-row-reverse justify-between">
                          <div className="w-5 h-5">
                            {form.values.accounts.length > 1 && (
                              <XIcon
                                className=" fill-gray-500 cursor-pointer hover:fill-gray-700"
                                onClick={() => {
                                  const idx = form.values.accounts.findIndex(
                                    (a) => a.key === account.key
                                  );
                                  formArray.remove(idx);
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <RecipientInformation
                            accounts={accounts}
                            index={index}
                            form={form}
                          />
                        </div>
                      </div>
                    </div>
                    {index === form.values.accounts.length - 1 && (
                      <div
                        className="border-dashed border-2 border-gray-300 p-2 rounded-sm text-gray-600 cursor-pointer text-center"
                        onClick={(e) => {
                          e.preventDefault();
                          formArray.push({
                            key: uuidv4(),
                            name: "",
                            emailAddress: "",
                            mobileNumber: "",
                            accessCodeMethod: "Mobile",
                          });
                        }}
                      >
                        + Add Recipient
                      </div>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          />
        )}
      </div>
    </>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SearcAccountField({
  name,
  label,
  accounts,
  onSelectAccount,
  initialValue,
  onBlur,
}) {
  const [query, setQuery] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);

  const filteredAccounts =
    query === ""
      ? accounts
      : accounts?.filter((account) => {
          return account.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    setQuery(initialValue);
  }, []);

  useEffect(() => {
    setSelectedAccount({ name: query, email: "", verificationPhoneNumber: "" });
  }, [query]);

  useEffect(() => {
    onSelectAccount({
      name: selectedAccount?.name,
      email: selectedAccount?.email,
      verificationPhoneNumber: selectedAccount?.verificationPhoneNumber,
    });
  }, [selectedAccount]);

  return (
    <Combobox as="div" value={selectedAccount} onChange={setSelectedAccount}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        {label}
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          name={name}
          onBlur={onBlur}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) => person?.name}
        />
        {filteredAccounts?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredAccounts.map((account) => (
              <Combobox.Option
                key={account.key}
                value={account}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {account.name}
                      </span>
                      <span
                        className={classNames(
                          "ml-2 truncate text-gray-500",
                          active ? "text-blue-200" : "text-gray-500"
                        )}
                      >
                        {account.email}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-blue-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

function RecipientInformation({ accounts, index, form }) {
  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-3 sm:col-span-2">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <SearcAccountField
            initialValue={form.values.accounts[index]?.name}
            accounts={accounts}
            label="Name*"
            name={`accounts[${index}].name`}
            onBlur={form.handleBlur}
            onSelectAccount={({ name, email, verificationPhoneNumber }) => {
              form.setFieldValue(`accounts[${index}].name`, name);
              if (email) {
                form.setFieldValue(`accounts[${index}].emailAddress`, email);
              }

              if (verificationPhoneNumber) {
                form.setFieldValue(
                  `accounts[${index}].accessCodeMethod`,
                  "Mobile"
                );
                form.setFieldValue(
                  `accounts[${index}].mobileNumber`,
                  verificationPhoneNumber
                );
              } else {
                if (email) {
                  form.setFieldValue(
                    `accounts[${index}].accessCodeMethod`,
                    "Email"
                  );
                  form.setFieldValue(`accounts[${index}].mobileNumber`, "");
                } else {
                  form.setFieldValue(`accounts[${index}].mobileNumber`, "");
                }
              }
            }}
          />

          {form.touched?.accounts?.length &&
            form.touched?.accounts[index]?.name &&
            form?.errors?.accounts &&
            form?.errors?.accounts[index] && (
              <Validator
                fieldName="name"
                errorsList={form.errors.accounts[index]}
              ></Validator>
            )}
        </div>
      </div>
      <div className="col-span-3 sm:col-span-2">
        <label htmlFor={"emailAddress_"} className="formLabel">
          Email Address*
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            name={`accounts[${index}].emailAddress`}
            value={form.values.accounts[index].emailAddress}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />{" "}
          {form.touched?.accounts?.length &&
            form.touched?.accounts[index]?.emailAddress &&
            form?.errors?.accounts &&
            form?.errors?.accounts[index] && (
              <Validator
                fieldName="emailAddress"
                errorsList={form.errors.accounts[index]}
              ></Validator>
            )}
        </div>
      </div>
      <div className="col-span-3 sm:col-span-3">
        <div>
          <label htmlFor="account" className="formLabel">
            Send access codes to:
          </label>
          <p className="formHelpText">
            Access codes are temporary passwords sent to the recipient when they
            login to DocuPoint.
          </p>
        </div>
        <div className="flex">
          <div className={`flexNone ${styles.accessCodeMethod}`}>
            <input
              type="radio"
              id={`accessMethod_mobile${index}`}
              name={`accounts[${index}].accessCodeMethod`}
              checked={
                form.values.accounts[index].accessCodeMethod === "Mobile"
              }
              value="Mobile"
              className="mr-1"
              onChange={form.handleChange}
            />
            <label htmlFor={`accessMethod_mobile${index}`}>
              Mobile Number*
            </label>
          </div>
          <div className={`flexNone ${styles.accessCodeMethod}`}>
            <input
              type="text"
              name={`accounts[${index}].mobileNumber`}
              value={form.values.accounts[index].mobileNumber}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              disabled={
                form.values.accounts[index].accessCodeMethod !== "Mobile"
              }
            />{" "}
            {form.touched?.accounts?.length &&
              form.touched?.accounts[index]?.mobileNumber &&
              form?.errors?.accounts &&
              form?.errors?.accounts[index] && (
                <Validator
                  fieldName="mobileNumber"
                  errorsList={form.errors.accounts[index]}
                ></Validator>
              )}
          </div>
        </div>
        <div className="col-span-3 sm:col-span-2">
          <input
            type="radio"
            id={`accessMethod_email${index}`}
            name={`accounts[${index}].accessCodeMethod`}
            checked={form.values.accounts[index].accessCodeMethod === "Email"}
            className="mr-1"
            value="Email"
            onChange={form.handleChange}
          />
          <label htmlFor={`accessMethod_email${index}`}>
            Email Address Above
          </label>
        </div>
      </div>
    </div>
  );
}

function Documents({ form }) {
  const [templates, setTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [documentError, setDocumentError] = useState("");

  const handleAddDocumentChange = (event) => {
    const documents = form.values.documents; //state.documents;
    const nameExists = documents.some(
      (d) => d.name.toLowerCase() === event.target.value.toLowerCase()
    );
    if (nameExists) {
      setDocumentError(
        `${event.target.value} already exists in the list of documents`
      );
      return;
    } else {
      setDocumentError("");
    }
  };
  const handleAddDocumentKeyPress = async function (event) {
    const documents = form.values.documents; //state.documents;
    if (!!documentError) return;

    switch (event.key) {
      case "Enter":
        const value = event.target.value;
        if (value.length > 0) {
          documents.push({ key: uuidv4(), name: value });
        }
        event.preventDefault();
        break;
      case "v":
        if (event.ctrlKey) {
          const content = await navigator.clipboard.readText();
          if (content.indexOf("\n") == -1) {
            return;
          }
          const lines = content.split("\n");
          lines.forEach((l) => {
            l = l.trim();
            if (l.length > 0) {
              documents.push({ key: uuidv4(), name: l });
            }
          });
        } else {
          return true;
        }
        break;
      default:
        return true;
    }
    form.setValues((values) => ({ ...values, documents }));
    event.target.value = "";
  };
  const handleRemoveDocument = function (keyToRemove) {
    const documents = form.values.documents.filter(
      (document) => document.key !== keyToRemove
    );
    form.setValues((values) => ({ ...values, documents }));
  };

  const handleUpdateDocumentName = (keyToUpdate, value) => {
    const documents = form.values.documents;

    const newDocuments = documents.map((d) => {
      if (d.key !== keyToUpdate) return { ...d };
      d.name = value;

      return { ...d };
    });

    form.setFieldValue("documents", newDocuments);
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const loadTemplates = async () => {
    try {
      const url = `/api/documentrequesttemplates`;
      const resp = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (resp.status >= 400) throw "Error in loading document details";
      const data = await resp.json();

      setTemplates(data);
    } catch (err) {}
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  useEffect(() => {
    if (selectedTemplate !== 0) {
      const documents = form.values.documents;
      const templateDetails = templates.filter(
        (template) => template.key === selectedTemplate
      );

      if (!!templateDetails && templateDetails.length > 0) {
        const existingDocuments = documents.map((doc) => doc.name);
        const newDocuments = templateDetails[0].documentTypes?.filter(
          (d) => !existingDocuments.some((ed) => ed === d.name)
        );
        const selectedDocTypes = newDocuments?.map((t) => ({
          key: uuidv4(),
          name: t.name,
        }));
        if (!!selectedDocTypes) {
          form.setValues((values) => ({
            ...values,
            documents: [...documents, ...selectedDocTypes],
          }));
        }
      }
      setSelectedTemplate(0);
    }
  }, [selectedTemplate]);

  return (
    <>
      <div className="formSectionLeft">
        <div className="px-4 sm:px-0">
          <h3 className="formSectionHeader">Documents</h3>
          <p className="formSectionHeaderDescription">
            What documents are needed?
          </p>
        </div>
      </div>
      <div className="formSectionRight">
        <div className="formSectionRightPanel">
          <div className="flex-auto">
            <div className={`rounded-md`}>
              {templates?.length > 0 && (
                <div className="mb-5">
                  <div className="col-span-3 sm:col-span-2">
                    <select
                      name="template"
                      id="template"
                      value={selectedTemplate}
                      onChange={handleTemplateChange}
                    >
                      <option value="0">Select a checklist</option>
                      {templates?.map((template) => (
                        <option key={template.key} value={template.key}>
                          {template.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div>
                {form.values.documents?.map((itm, idx) => {
                  return (
                    itm.updateStatus !== "Removed" && (
                      <div
                        className="flex group items-center mt-1 text-gray-600"
                        key={itm.key}
                      >
                        <ClickToEditInput
                          value={itm.name}
                          onChange={(e) =>
                            handleUpdateDocumentName(itm.key, e.target.value)
                          }
                        />
                        <div
                          className="opacity-0 actionButton group-hover:opacity-100"
                          style={{ padding: "3px", marginTop: "1px" }}
                          onClick={() => handleRemoveDocument(itm.key)}
                        >
                          <XIcon />
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              <div>
                <input
                  type="text"
                  className={`${styles.addDocument}`}
                  onKeyDown={handleAddDocumentKeyPress}
                  onChange={handleAddDocumentChange}
                  placeholder="Enter a document name here and press enter"
                />
                {form.touched.documents && (
                  <Validator
                    fieldName="documents"
                    errorsList={form.errors}
                  ></Validator>
                )}
                <Validator
                  fieldName="documents"
                  errorsList={{ documents: documentError }}
                ></Validator>
              </div>
              {/* <div className="mt-5">
                {form.values.documents.map((itm, idx) => (
                  <div className="flex group items-center mt-1" key={itm.key}>
                    <div>{itm.name}</div>
                    <div
                      className="hidden actionButton group-hover:flex"
                      style={{ padding: "3px", "margin-top": "1px" }}
                      onClick={() => handleRemoveDocument(itm.key)}
                    >
                      <XIcon />
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function MoreInformation(props) {
  const { form } = props;
  return (
    <>
      <div className="formSectionLeft">
        <div className="px-4 sm:px-0">
          <h3 className="formSectionHeader">Additional Information</h3>
          <p className="formSectionHeaderDescription">
            Provide more information for the recipient of this request.
          </p>
        </div>
      </div>
      <div className="formSectionRight">
        <div className="formSectionRightPanel">
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3 sm:col-span-2">
              <label htmlFor="requestTitle" className="formLabel">
                Request Title*
              </label>
              <p className="formHelpText">
                The title of the request that the recipient will see in emails
              </p>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  id="requestTitle"
                  value={form.values.requestTitle}
                  name="requestTitle"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />{" "}
                {form.touched.requestTitle && (
                  <Validator
                    fieldName="requestTitle"
                    errorsList={form.errors}
                  ></Validator>
                )}
              </div>
            </div>
            <div className="col-span-3 sm:col-span-2">
              <label htmlFor="requestDescription" className="formLabel">
                Description*
              </label>
              <p className="formHelpText">
                Any additional information the recipient will need to fulfill
                this request.
              </p>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  type="text"
                  value={form.values.requestDescription}
                  name="requestDescription"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  className="w-full"
                  rows="4"
                ></textarea>{" "}
                {form.touched.requestDescription && (
                  <Validator
                    fieldName="requestDescription"
                    errorsList={form.errors}
                  ></Validator>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Message(props) {
  const { form } = props;
  return (
    <>
      <div className="formSectionLeft">
        <div className="px-4 sm:px-0">
          <h3 className="formSectionHeader">Message</h3>
          <p className="formSectionHeaderDescription">
            Send a message to the recipient
          </p>
        </div>
      </div>
      <div className="formSectionRight">
        <div className="formSectionRightPanel">
          <div className="w-full relative">
            <p className="formSectionHeaderDescription text-gray-900 font-medium mb-3">
              Send a message to the recipient regarding these changes (optional)
            </p>
            <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden p-2">
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                rows={3}
                name="emailBody"
                id="emailBody"
                className="block w-full py-3 border-0 focus:border-0 sm:text-sm focus:outline-0 shadow-none"
                placeholder="Add your message..."
                value={form.values.emailBody}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function useCreateRequests(customerRequestKey) {
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  const onCreateRequest = async (request) => {
    setStatus("loading");
    try {
      let url = "/api/customerrequests";
      let method = "POST";
      if (customerRequestKey) {
        url = `/api/customerrequests/${customerRequestKey}`;
        method = "PUT";
      }
      const result = await postJson(url, request, method);
      if (result.status >= 400) throw "Error";
      setStatus("success");
    } catch (e) {
      setStatus("error");
      setError("Error in saving!");
    }
  };
  return { status, error, onCreateRequest };
}

const AccountsSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  emailAddress: Yup.string()
    .email("Invalid email format")
    .required("Email Address is required"),
  accessCodeMethod: Yup.string().required("Select Access Method"),
  mobileNumber: Yup.string().when("accessCodeMethod", {
    is: (accessCodeMethod) => accessCodeMethod === "Mobile",
    then: Yup.string().required("Mobile Number is required").nullable(),
    otherwise: Yup.string().nullable(),
  }),
});

const NewRequestSchema = Yup.object({
  requestName: Yup.string().required("Request Name is required"),
  requestTitle: Yup.string().required("Title is required"),
  requestDescription: Yup.string().required("Description is required"),
  mobileNumber: Yup.string().when("accessCodeMethod", {
    is: (accessCodeMethod) => accessCodeMethod === "Mobile",
    then: Yup.string().required("Mobile Number is required"),
    otherwise: Yup.string(),
  }),
  documents: Yup.array().min(1, "Enter at least one document"),
  emailBody: Yup.string(),
  accounts: Yup.array().of(AccountsSchema),
});

function CreateRequestScreen() {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const { customerRequestKey } = useParams();
  const baseUrl = routeMatch.url.endsWith("/")
    ? routeMatch.url.substring(0, routeMatch.url.length - 1)
    : routeMatch.url;
  const returnUrl = customerRequestKey
    ? `/requests/${customerRequestKey}`
    : "/requests";
  const [state, setState] = useState({
    status: "loading",
    error: null,
    unauthorized: false,
    validationErrors: {},
    showErrorModal: false,
    fields: {
      requestName: "",
      account: "",
      name: "",
      emailAddress: "",
      mobileNumber: "",
      accessCodeMethod: "Mobile",
    },
    documents: [],
  });
  const [accountName, setAccountName] = useState("");
  const [status, setStatus] = useState("");

  const {
    status: createRequestStatus,
    error: createRequestError,
    onCreateRequest,
  } = useCreateRequests(customerRequestKey);

  const form = useFormik({
    initialValues: {
      requestName: "",
      account: "",
      requestTitle: "",
      requestDescription: "",
      documents: [],
      accounts: [
        {
          key: uuidv4(),
          name: "",
          emailAddress: "",
          mobileNumber: "",
          accessCodeMethod: "Mobile",
        },
      ],
      emailBody: "",
    },
    validationSchema: NewRequestSchema,
    onSubmit: async (formData) => {
      const request = {
        requestName: formData.requestName,
        account: formData.account,
        title: formData.requestTitle,
        status: "Pending",
        message: formData.requestDescription,
        emailSubject: formData.name,
        emailBody: formData.emailBody,
        accounts: formData.accounts.map((account) => ({
          ...account,
          key: null,
          email: account.emailAddress,
          verificationPhoneNumber:
            account.accessCodeMethod === "Mobile" ? account.mobileNumber : "",
        })),
        docRequests: formData.documents.map((doc) => ({
          key: doc.docRequestKey,
          name: doc.name,
          status: "Active",
        })),
      };
      await onCreateRequest(request);
    },
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    async function loadData() {
      const url = customerRequestKey
        ? `/api/customerrequests/${customerRequestKey}/edit`
        : "/api/customerrequests/create";
      const resp = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (isResponseUnauthorized(resp)) {
        setStateValue("unauthorized", true);
      } else if (resp.ok) {
        const data = await resp.json();
        form.resetForm();
        form.setFieldValue(
          "requestName",
          valueOrEmpty(data.customerRequest.requestName)
        );
        form.setFieldValue(
          "account",
          valueOrEmpty(data.customerRequest.account)
        );
        form.setFieldValue(
          "requestTitle",
          valueOrEmpty(data.customerRequest.title)
        );
        form.setFieldValue(
          "requestDescription",
          valueOrEmpty(data.customerRequest.message)
        );
        if (
          data.customerRequest.accounts &&
          data.customerRequest.accounts.length > 0
        ) {
          const accounts = data.customerRequest.accounts;
          form.setFieldValue(
            "accounts",
            accounts.map((a) => ({
              ...a,
              emailAddress: a.email,
              accessCodeMethod: !a.verificationPhoneNumber ? "Email" : "Mobile",
              mobileNumber: a.verificationPhoneNumber,
            }))
          );
        }

        if (data.customerRequest.docRequests) {
          const documents = [];
          data.customerRequest.docRequests.forEach((x) => {
            documents.push({
              key: uuidv4(),
              docRequestKey: x.key,
              name: x.name,
            });
          });
          form.setFieldValue("documents", documents);
        }

        const updates = {
          status: "loaded",
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await resp.text();
        console.log(`Error loading global settings data: ${message}`);

        const updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }

    loadData();
  }, [customerRequestKey]);

  useEffect(() => {
    if (form.values.accessCodeMethod === "Email") {
      form.setFieldValue("mobileNumber", "");
    }
  }, [form.values.accessCodeMethod]);

  useEffect(() => {
    if (createRequestStatus === "loading") {
      setStatus("loading");
    } else if (createRequestStatus === "success") {
      const tType = !customerRequestKey ? "created" : "updated";
      toastSuccess(`Request succesfuly ${tType}!`);
      setTimeout(() => {
        history.push(returnUrl);
      }, 2000);
    } else if (createRequestStatus === "error") {
      toastError(createRequestError);
      setStatus("loaded");
    } else {
      setStatus("loaded");
    }
  }, [createRequestStatus]);

  return (
    <DocuPointLayout
      backgroundColor="bg-gray-100"
      unauthorized={state.unauthorized}
    >
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {customerRequestKey && (
            <BreadCrumb_Requests_Request
              customerRequestKey={customerRequestKey}
              accountName={accountName}
            />
          )}
          {!customerRequestKey && <BreadCrumb_Requests_Create />}
          <FormikProvider value={form}>
            <form>
              <div className="formSection">
                <RequestInformation form={form} />
              </div>

              <div className="formSection mt-4">
                <RecipientInformations form={form} />
              </div>

              <div className="formSection mt-4">
                <Documents form={form} />
              </div>

              <div className="formSection mt-4">
                <MoreInformation form={form} />
              </div>
              {!!customerRequestKey && (
                <div className="formSection mt-4">
                  <Message form={form} />
                </div>
              )}
            </form>
          </FormikProvider>
          <div className="mt-4">
            <div className="inline-block w-40 group">
              <ThemedButton
                type="submit"
                onClick={form.handleSubmit}
                disabled={status === "loading"}
              >
                {status === "loading" ? (
                  <div
                    style={{ borderTopColor: "transparent" }}
                    className="w-4 h-4 border-4 border-white-400 border-solid rounded-full animate-spin"
                  />
                ) : (
                  "Send"
                )}
              </ThemedButton>
            </div>
            <div className="inline-block w-40 ml-2">
              <Link to={returnUrl}>
                <CancelButton>Cancel</CancelButton>
              </Link>
            </div>
          </div>
        </div>
      )}

      <ErrorModal
        show={state.showErrorModal}
        onClose={() => setStateValue("showErrorModal", false)}
      ></ErrorModal>
    </DocuPointLayout>
  );
}

export { CreateRequestScreen };
