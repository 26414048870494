import React from 'react'

function ErrorFallback({error}){
    debugger;
    return (
        <div role="alert" style={{ color: 'red' }}>
            {error?.message}
        </div>
    )
}

export {ErrorFallback}