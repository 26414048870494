import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ThemedButton } from "../../components/buttons/themedButton";
import { getLoginDetails } from "../../utils/utils";
import { Paragraphs } from "../../components/paragraphs";

function SignatureModal({ show, errorMessage, onCancel, onSign }) {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [canSend, setCanSend] = useState(false);
  const { tenantInfo } = getLoginDetails();

  useEffect(() => {
    setCanSend(name.length > 2);
  }, [name]);

  const onSignClick = () => {
    onSign({ comments: comment, legalName: name });
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div className="text-xl text-center font-bold pb-2">
                Send Documents
              </div>
              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                <div
                  className={`${
                    errorMessage ? "" : "hidden"
                  } text-red-600 mb-2`}
                >
                  {errorMessage}
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Comments
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="comments"
                      name="comments"
                      rows={3}
                      className="p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder={`Type a message to your ${tenantInfo.name} representative here...`}
                      value={comment}
                      onChange={(evt) => setComment(evt.target.value)}
                    />
                  </div>
                </div>
                <div className="relative py-4 mt-2">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-3 bg-white text-lg font-medium text-gray-900">
                      Electronic Signature
                    </span>
                  </div>
                </div>
                <div className="border-gray-300 border rounded-md p-2">
                  <div className="text-sm text-gray-500 leading-relaxed">
                    <Paragraphs text={tenantInfo.signatureText} />
                  </div>
                  <div className="sm:px-16 my-6">
                    <label
                      htmlFor="legal-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Full Legal Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="legal-name"
                        id="legal-name"
                        className="p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-8 flex">
                <button
                  type="button"
                  onClick={onCancel}
                  className="w-full mr-2 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm"
                >
                  Cancel
                </button>
                <ThemedButton
                  type="button"
                  onClick={onSignClick}
                  disabled={!canSend}
                >
                  Send
                </ThemedButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { SignatureModal };
