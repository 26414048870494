import React, { Fragment, useEffect, useState } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import { ToggleButton } from "../../components/buttons/toggleButton";
import {
  CancelButton,
  ThemedButton,
} from "../../components/buttons/themedButton";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { postJson } from "../../utils/api-client";
import { Validator } from "../../components/validator";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { RefreshIcon } from "@heroicons/react/solid";
import { DropDown } from "../../components/buttons/dropDown";
import { GlobalHeader } from "./globalHeader";
import {
  toastSuccess,
  checkRequiredFields,
  toastError,
} from "../../utils/utils.js";
import styles from "./index.module.css";
import { TestEmailModal } from "./testEmailModal";
import { DocuPointLayout } from "../../components/docupointLayout";

function GlobalSettingsScreen() {
  const [state, setState] = useState({
    status: "loading",
    validationErrors: {},
    showErrorModal: false,
    modalErrorMessage: null,
    saving: false,
    showTestEmailModal: false,
    fields: {
      enableDocumentRetrieval: true,
      delayDocumentRetrievalMinutes: 0,
      purgeRetrievedSubmissions: false,
      purgeSubmissionsIntervalMinutes: 0,
      enableSwagger: false,
      maxDocumentsPerRequest: 1000,
      emailHost: "",
      emailPort: "",
      emailSSL: "",
      emailUserName: "",
      emailPassword: "",
      sentryEnvironment: "",
      sentryDsn: "",
      Auth0ManagementDomain: "",
      Auth0ManagementClientId: "",
      Auth0ManagementClientSecret: "",
    },
    sentryEnvironments: [],
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = function (event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const fields = state.fields;
    fields[name] = value;
    setStateValue("fields", fields);
  };

  const handleToggleChange = function (field) {
    const fields = state.fields;
    fields[field] = !fields[field];
    setStateValue("fields", fields);
  };

  const validateForm = function () {
    const values = state.fields;
    const validationErrors = {};
    checkRequiredFields(
      values,
      [
        "maxDocumentsPerRequest",
        "delayDocumentRetrievalMinutes",
        "purgeSubmissionsIntervalMinutes",
        "emailHost",
        "emailPort",
        "emailUserName",
        "emailPassword",
        "sentryEnvironment",
        "sentryDsn",
      ],
      validationErrors
    );
    const valid = Object.keys(validationErrors).length == 0;

    setState((prevState) => ({
      ...prevState,
      validationErrors: validationErrors,
    }));

    if (!valid) {
      toastError(
        "The information you entered could not be submitted. Please review the errors listed"
      );
    }

    return valid;
  };

  const handleSave = async function () {
    if (!validateForm()) {
      return;
    }

    const data = state.fields;
    const resp = await postJson("/api/GlobalSettings", data, "PUT");

    if (resp.ok) {
      toastSuccess("Changes saved!");
    } else {
      setStateValue("showErrorModal", true);
    }
  };

  const handleShowTestEmail = (e) => {
    e.preventDefault();
    setStateValue("showTestEmailModal", true);
  };

  const closeErrorModal = () => {
    setState((prevState) => ({
      ...prevState,
      showErrorModal: false,
      modalErrorMessage: null,
    }));
  };

  useEffect(() => {
    async function loadData() {
      const url = `/api/GlobalSettings`;
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (response.ok) {
        const data = await response.json();
        const updates = {
          status: "loaded",
          fields: data,
          sentryEnvironments: data.sentryEnvironments,
        };

        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await response.text();
        console.log(`Error loading global settings data: ${message}`);

        const updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }

    loadData();
  }, []);

  return (
    <DocuPointLayout>
      <GlobalHeader location="Settings" />
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={() => window.location.reload()}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-gray-100 p-4">
          <form>
            <div className="formSection">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Document Settings</h3>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="enableDocumentRetrieval"
                        className="formLabel"
                      >
                        Enable Retrievals
                      </label>
                      <p className="formHelpText">
                        Breaker switch in case a malicious file is detected and
                        needs to be removed
                      </p>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <ToggleButton
                          checked={state.fields.enableDocumentRetrieval}
                          onChange={(e) => {
                            handleToggleChange("enableDocumentRetrieval");
                          }}
                        ></ToggleButton>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name" className="formLabel">
                        Delay Retrievals (Minutes)
                      </label>
                      <p className="formHelpText">
                        Amount of time to give the virus scanner to report a
                        malicious file
                      </p>
                      <input
                        type="text"
                        value={state.fields.delayDocumentRetrievalMinutes}
                        name="delayDocumentRetrievalMinutes"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="delayDocumentRetrievalMinutes"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="purgeRetrievedSubmissions"
                        className="formLabel"
                      >
                        Purge Retrieved Submissions
                      </label>
                      <p className="formHelpText">
                        Whether to purge documents from storage once they are
                        retrieved by BankPoint
                      </p>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <ToggleButton
                          checked={state.fields.purgeRetrievedSubmissions}
                          onChange={(e) => {
                            handleToggleChange("purgeRetrievedSubmissions");
                          }}
                        ></ToggleButton>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name" className="formLabel">
                        Purge Submissions Interval (Minutes)
                      </label>
                      <p className="formHelpText">
                        Amount of time before purging a retrieved submission
                      </p>
                      <input
                        type="text"
                        value={state.fields.purgeSubmissionsIntervalMinutes}
                        name="purgeSubmissionsIntervalMinutes"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="purgeSubmissionsIntervalMinutes"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name" className="formLabel">
                        Max Files Per Request
                      </label>
                      <p className="formHelpText">
                        Maximum number of documents which can be uploaded per
                        customer request
                      </p>
                      <input
                        type="text"
                        value={state.fields.maxDocumentsPerRequest}
                        name="maxDocumentsPerRequest"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="maxDocumentsPerRequest"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="formSection mt-4">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Email Settings</h3>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="emailHost" className="formLabel">
                        Email Host
                      </label>
                      <input
                        type="text"
                        value={state.fields.emailHost}
                        name="emailHost"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="emailHost"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="emailPort" className="formLabel">
                        Email Port
                      </label>
                      <input
                        type="text"
                        value={state.fields.emailPort}
                        name="emailPort"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="emailPort"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="purgeRetrievedSubmissions"
                        className="formLabel"
                      >
                        Use SSL
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <ToggleButton
                          checked={state.fields.emailSSL}
                          onChange={(e) => {
                            handleToggleChange("emailSSL");
                          }}
                        ></ToggleButton>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="emailUserName" className="formLabel">
                        User Name
                      </label>
                      <input
                        type="text"
                        value={state.fields.emailUserName}
                        name="emailUserName"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="emailUserName"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="emailPassword" className="formLabel">
                        Password
                      </label>
                      <input
                        type="password"
                        value={state.fields.emailPassword}
                        name="emailPassword"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="emailPassword"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <div className="w-40">
                        <CancelButton onClick={handleShowTestEmail}>
                          Test Email
                        </CancelButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="formSection mt-4">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Auth0 Management API</h3>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="auth0ManagementDomain"
                        className="formLabel"
                      >
                        Domain
                      </label>
                      <input
                        type="text"
                        value={state.fields.auth0ManagementDomain}
                        name="auth0ManagementDomain"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="auth0ManagementDomain"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="auth0ManagementClientId"
                        className="formLabel"
                      >
                        Client Id
                      </label>
                      <input
                        type="text"
                        value={state.fields.auth0ManagementClientId}
                        name="auth0ManagementClientId"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="auth0ManagementClientId"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="auth0ManagementClientSecret"
                        className="formLabel"
                      >
                        Client Secret
                      </label>
                      <input
                        type="password"
                        value={state.fields.auth0ManagementClientSecret}
                        name="auth0ManagementClientSecret"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="auth0ManagementClientSecret"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="formSection mt-4">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Sentry Settings</h3>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="sentryDsn" className="formLabel">
                        DSN
                      </label>
                      <input
                        type="text"
                        value={state.fields.sentryDsn}
                        name="sentryDsn"
                        onChange={handleInputChange}
                      />
                      <Validator
                        fieldName="sentryDsn"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="emailPort" className="formLabel">
                        Environment
                      </label>
                      <DropDown
                        name="sentryEnvironment"
                        options={state.sentryEnvironments}
                        value={state.fields.sentryEnvironment}
                        onChange={handleInputChange}
                      ></DropDown>
                      <Validator
                        fieldName="sentryEnvironment"
                        errorsList={state.validationErrors}
                      ></Validator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="formSection mt-4">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Miscellaneous Settings</h3>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="enableSwagger" className="formLabel">
                        Enable Swagger Docs
                      </label>
                      <ToggleButton
                        checked={state.fields.enableSwagger}
                        onChange={(e) => {
                          handleToggleChange("enableSwagger");
                        }}
                      ></ToggleButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="mt-4">
            <div className="inline-block w-40">
              <ThemedButton onClick={handleSave}>Save</ThemedButton>
            </div>
            <div className="inline-block w-40 ml-2">
              <Link to="/">
                <CancelButton>Cancel</CancelButton>
              </Link>
            </div>
          </div>
        </div>
      )}

      <ErrorModal
        show={state.showErrorModal}
        errorMessage={state.modalErrorMessage}
        onClose={closeErrorModal}
      ></ErrorModal>
      <TestEmailModal
        show={state.showTestEmailModal}
        emailSettings={state.fields}
        onClose={() => setStateValue("showTestEmailModal", false)}
      />
    </DocuPointLayout>
  );
}

export { GlobalSettingsScreen };
