import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ThemedButton } from "../../components/buttons/themedButton";
import { getTenantInfo } from "../../utils/utils";
import { Paragraphs } from "../../components/paragraphs";

function TermsModal({ show, onAccept }) {
  const [state, setState] = useState({
    accept: false,
  });

  useEffect(() => {});

  const onAcceptChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setState((prevState) => ({ ...prevState, accept: value }));
  };

  const onContinueClick = () => {
    if (state.accept) {
      onAccept();
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div className="text-xl text-center font-bold pb-2">
                Terms of Use
              </div>
              <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                Please take a moment to read and accept our{" "}
                <a className="colored" href="/termsofuse" target="_blank">
                  terms of use
                </a>
                ,{" "}
                <a className="colored" href="/privacypolicy" target="_blank">
                  privacy policy
                </a>{" "}
                and{" "}
                <a className="colored" href="/cookiepolicy" target="_blank">
                  cookie policy
                </a>{" "}
                before proceeding.
                <div className="mt-4">
                  <input
                    type="checkbox"
                    id="acceptTerms"
                    onChange={onAcceptChange}
                  />
                  <label for="acceptTerms" className="ml-2">
                    I accept the terms of use, privacy policy and cookie policy
                  </label>
                </div>
              </div>
              <div className="mt-8 flex">
                <ThemedButton
                  type="button"
                  disabled={!state.accept}
                  onClick={onContinueClick}
                >
                  Continue
                </ThemedButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { TermsModal };
