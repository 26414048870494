import React, { useEffect, useState, Fragment } from "react";
import { ThemedButton } from "../../components/buttons/themedButton";
import { postDownloadRequest, postJson } from "../../utils/api-client";
import {
  ChatAltIcon,
  CheckIcon,
  DocumentIcon,
  PaperClipIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { classNames, toastError, toastSuccess } from "../../utils/utils";
import { DocumentTextIcon } from "@heroicons/react/outline";

const useRequestMessage = () => {
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const onSendMessage = async (key, messageRequest) => {
    setStatus("loading");

    try {
      const result = await postJson(
        `/api/customerrequests/${key}/email`,
        messageRequest,
        "POST"
      );
      if (result.status >= 400) throw "Error";
      setStatus("success");
      setData(messageRequest);
    } catch (e) {
      setStatus("error");
      setError("Error in sending email!");
    }
  };
  return { status, error, data, onSendMessage };
};

const SendMessageSchema = Yup.object({
  message: Yup.string().required(),
});

function RequestMessages(props) {
  const {
    customerRequestKey,
    requestTitle,
    recipient,
    initialState,
    canSendMessage,
    recipientName,
  } = props;

  const [requestMessages, setRequestMessages] = useState(initialState);
  const { status, error, data, onSendMessage } = useRequestMessage();

  const messageForm = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: SendMessageSchema,
    onSubmit: async (formData, { resetForm }) => {
      const messageRequest = {
        emailSubject: `Re: ${requestTitle}`,
        emailBody: formData.message,
      };
      await onSendMessage(customerRequestKey, messageRequest);
      resetForm();
    },
    validateOnMount: true,
  });

  useEffect(() => {
    if (status === "success") {
      setRequestMessages((previousData) => {
        const newData = [...previousData];
        newData.push({
          id: uuidv4(),
          message: data.emailBody,
          dateCreatedUtc: new Date(),
        });

        return newData;
      });
    }
  }, [status]);

  dayjs.extend(utc);
  dayjs.extend(relativeTime);

  return (
    <form className="detailsSection">
      <div className="px-4 py-4 sm:px-4">
        <h2 className="detailsHeader">Messages</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {requestMessages?.length > 0 ? (
          <div
            className={classNames(
              canSendMessage ? "max-h-80" : "",
              "flow-root max-h-80 min-h-max overflow-y-scroll"
            )}
          >
            <ul role="list" className="-mb-8">
              {requestMessages?.map((requestMessage, index) => (
                <li key={requestMessage.id}>
                  <div className="relative pb-8">
                    {index !== requestMessage.length - 1 ? (
                      <span
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      <>
                        <div className="relative">
                          <UserCircleIcon
                            className="h-10 w-10 text-gray-300"
                            aria-hidden="true"
                          />
                          <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                            <ChatAltIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm"></div>
                            <p className="mt-0.5 text-sm text-gray-500">
                              Sent{" "}
                              {dayjs
                                .utc(requestMessage.dateCreatedUtc)
                                .fromNow()}
                            </p>
                          </div>
                          <div className="mt-2 text-sm text-gray-700">
                            <p>{requestMessage.message}</p>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="text-gray-500">No messages have been sent</div>
        )}
        {canSendMessage && (
          <div className="mt-4 w-full max-w-lg relative">
            <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden p-2">
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                rows={3}
                name="message"
                id="message"
                className="block w-full py-3 border-0 focus:border-0 sm:text-sm focus:outline-0 shadow-none"
                placeholder={`Send a message to ${recipientName}...`}
                value={messageForm.values.message}
                onChange={messageForm.handleChange}
              />
              <div className="py-2" aria-hidden="true">
                <div className="py-px">
                  <div className="h-9" />
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 right-0 pl-3 pr-2 py-2">
              <div className="flex-shrink-0">
                <ThemedButton
                  fitted={true}
                  onClick={messageForm.handleSubmit}
                  type="submit"
                  disabled={status === "loading" || !messageForm.isValid}
                >
                  {status === "loading" ? (
                    <div
                      style={{ borderTopColor: "transparent" }}
                      className="w-4 h-4 border-4 border-white-400 border-solid rounded-full animate-spin"
                    />
                  ) : (
                    "Send"
                  )}
                </ThemedButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

function AdditionalInformation(props) {
  const { data } = props;
  return (
    <div className="detailsSection">
      <div className="px-4 py-4 sm:px-4">
        <h2 className="detailsHeader">Additional Information</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="text-sm font-medium text-gray-500">Request Title</div>
        <div className="mt-1 text-sm text-gray-900">{data.title}</div>
        <div className="mt-8">
          <div className="text-sm font-medium text-gray-500">
            Request Description
          </div>
          <div className="mt-1 text-sm text-gray-900">{data.message}</div>
        </div>
      </div>
    </div>
  );
}

function DocumentRequestDetails(props) {
  const {
    docRequest,
    onDownloadDocument,
    onDeclineDocument,
    onAcceptDocument,
  } = props;

  return (
    <tr key={docRequest.key}>
      <td className="align-top">
        <span className="text-gray-600 text-sm font-semibold">
          {docRequest.name}
        </span>
      </td>
      <td>
        {docRequest.docFiles.map(
          (docFile) =>
            docFile.status !== "Received" && (
              <div
                className="flex-1 flex items-center w-auto group h-5"
                key={docFile.key}
              >
                <DocumentTextIcon className="w-5 text-gray-400 mr-1" />
                {!docFile.purged ? (
                  <div
                    onClick={() => onDownloadDocument(docFile)}
                    className={`text-sm text-blue-600 hover:text-blue-500 w-full cursor-pointer ${
                      docFile.docFileStatus === "Declined" ? "line-through" : ""
                    }`}
                  >
                    {docFile.filename}
                  </div>
                ) : (
                  <div className="text-sm text-gray-500 w-full">
                    {docFile.filename} (Purged)
                  </div>
                )}
                <div className="w-20">
                  {docFile.docFileStatus === "Declined" ? (
                    <button
                      className="items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hidden group-hover:inline-flex"
                      onClick={() =>
                        onAcceptDocument(docRequest.key, docFile.key)
                      }
                    >
                      Accept
                    </button>
                  ) : (
                    <button
                      className="items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hidden group-hover:inline-flex"
                      onClick={() =>
                        onDeclineDocument(docRequest.key, docFile.key)
                      }
                    >
                      Decline
                    </button>
                  )}
                </div>
              </div>
            )
        )}
      </td>
    </tr>
  );
}

function DocumentRequests(props) {
  const { customerRequestKey, data } = props;

  const [documentRequests, setDocumentRequests] = useState(null);

  const handleDownloadDocument = (docFile) => {
    postDownloadRequest(`/api/docfile/${docFile.key}/download`);
  };

  const handleDeclineDocument = async (docRequestKey, docFileKey) => {
    const url = `/api/docfile/${docFileKey}/decline`;
    const response = await postJson(url);

    if (response.ok) {
      toastSuccess("Document has been declined");
      const updates = documentRequests.map((docRequest) => {
        if (docRequest.key === docRequestKey) {
          return {
            ...docRequest,
            docFiles: docRequest.docFiles.map((docFile) => ({
              ...docFile,
              docFileStatus:
                docFile.key === docFileKey ? "Declined" : docFile.docFileStatus,
            })),
          };
        } else {
          return { ...docRequest };
        }
      });

      setDocumentRequests(updates);
    } else {
      toastError("Error in declinding document");
    }
  };

  const handleAcceptDocument = async (docRequestKey, docFileKey) => {
    const url = `/api/docfile/${docFileKey}/accept`;
    const response = await postJson(url);

    if (response.ok) {
      toastSuccess("Document has been accepted");
      const updates = documentRequests.map((docRequest) => {
        if (docRequest.key === docRequestKey) {
          return {
            ...docRequest,
            docFiles: docRequest.docFiles.map((docFile) => ({
              ...docFile,
              docFileStatus:
                docFile.key === docFileKey
                  ? "Submitted"
                  : docFile.docFileStatus,
            })),
          };
        } else {
          return { ...docRequest };
        }
      });

      setDocumentRequests(updates);
    } else {
      toastError("Error in accepting document");
    }
  };

  useEffect(() => {
    setDocumentRequests(data);
  }, [data]);

  return (
    <div className="detailsSection">
      <div className="px-4 py-4 sm:px-4 flex justify-between ">
        <h2 className="detailsHeader">Documents</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <table className="dataTable w-full ">
          <thead>
            <tr>
              <th className="text-left">
                <span className="text-sm text-gray-500">
                  Requested Document
                </span>
              </th>
              <th className="text-left">
                <span className="text-sm text-gray-500">
                  Submitted Document
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {documentRequests?.map((docRequest) => (
              <DocumentRequestDetails
                key={docRequest.key}
                docRequest={docRequest}
                onDownloadDocument={handleDownloadDocument}
                onDeclineDocument={handleDeclineDocument}
                onAcceptDocument={handleAcceptDocument}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function RequestInformation(props) {
  const { data } = props;
  return (
    <div className="detailsSection">
      <div className="px-4 py-3 sm:px-4">
        <h2 className="detailsHeader">Request Information</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Request Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.requestName}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Account</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.account}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

function RecipientInformations(props) {
  const { data } = props;

  return (
    <div className="detailsSection">
      <div className="px-4 py-3 sm:px-4">
        <h2 className="detailsHeader">Recipient Information</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {data.map((account, index) => (
          <dl
            key={account.key}
            className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3"
          >
            <div className="sm:col-span-1">
              {index === 0 && (
                <dt className="text-sm font-medium text-gray-500">Recipient</dt>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                {`${account.name} (${account.email})`}
              </dd>
            </div>
            <div className="sm:col-span-1">
              {index === 0 && (
                <dt className="text-sm font-medium text-gray-500">
                  Send Access Codes To
                </dt>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                {!account.verificationPhoneNumber
                  ? account.email
                  : account.verificationPhoneNumber}
              </dd>
            </div>
          </dl>
        ))}
      </div>
    </div>
  );
}

export default function RequestDetails(props) {
  const { data } = props;

  const [recipientInformations, setRecipientInformations] = useState(null);
  const [documentRequests, setDocumentRequests] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const [messages, setMessages] = useState(null);
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    setRecipientInformations(data.accounts);
    setDocumentRequests(data.docRequests);
    setAdditionalInformation({ title: data.title, message: data.message });
    setMessages(data.requestMessages);

    setStatus("loaded");
  }, [data]);

  return (
    status === "loaded" && (
      <>
        {data.status == "Canceled" && (
          <div class="mt-8 detailsSection">
            <CheckIcon className="text-green-600 w-6 mr-1 inline-block" />
            Closed
          </div>
        )}
        <div className="mt-8">
          <RequestInformation data={data} />
        </div>
        <div className="mt-8">
          <RecipientInformations data={recipientInformations} />
        </div>
        <div className="mt-8">
          <DocumentRequests
            customerRequestKey={data.key}
            data={documentRequests}
          />
        </div>
        <div className="mt-8">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-5">
            <AdditionalInformation data={additionalInformation} />
            <RequestMessages
              customerRequestKey={data.key}
              requestTitle={data.title}
              // recipient={recipientInformations.email}
              // recipientName={recipientInformations.name}
              initialState={messages}
              canSendMessage={data.status !== "Canceled"}
            />
          </div>
        </div>
      </>
    )
  );
}
