import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { ThemedButton } from "../../components/buttons/themedButton";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { RefreshIcon, PlusIcon } from "@heroicons/react/solid";
import styles from "./index.module.css";
import { DocuPointLayout } from "../../components/docupointLayout";
import { getProfile, isInRole, setTenantContext } from "../../utils/utils";
import * as Constants from "../../constants";

function TenantsScreen() {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const [state, setState] = useState({
    status: "loading",
    error: null,
    tenants: null,
    showErrorModal: false,
  });

  const [canAddTenant, setCanAddTenant] = useState(false);

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const reload = () => {
    window.location.reload();
  };

  const handleTenantClick = async (tenant) => {
    await setTenantContext(tenant);
    window.location = "/requests";
  };

  const handleAddTenantClick = () => {
    history.push("/tenants/add");
  };

  useEffect(() => {
    async function loadData() {
      const profile = await getProfile();
      setCanAddTenant(isInRole(profile, Constants.ROLES_GLOBAL_ADMIN));

      const url = `/api/Tenants`;
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (response.ok) {
        const data = await response.json();
        const updates = {
          tenants: data,
          status: "loaded",
        };

        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await response.text();
        console.log(`Error loading tenants list: ${message}`);

        let updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }

    loadData();
  }, []);

  return (
    <DocuPointLayout>
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          {state.tenants.length > 0 ? (
            <>
              <div className="text-xl mb-4">Choose a Tenant</div>
              <div className="flex flex-wrap gap-4">
                {state.tenants.map((itm, idx) => {
                  return (
                    <div
                      key={`tenant_${idx}`}
                      onClick={() => handleTenantClick(itm)}
                    >
                      <div className={`${styles.tenantCard}`}>
                        <img
                          src={itm.logoUrl}
                          className="brandingLogo m-auto mb-4 mt-2"
                        />
                        <div>{itm.name}</div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  {canAddTenant && (
                    <div
                      className={`${styles.tenantCard}`}
                      onClick={handleAddTenantClick}
                    >
                      <PlusIcon className="m-auto mb-4 mt-2 h-8 text-gray-400" />
                      <span className="italic">Add a tenant</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div class="my-20">
              You have not been assigned to a DocuPoint tenant. Please contact
              your administrator for assistance.
            </div>
          )}
        </Fragment>
      )}

      <ErrorModal
        show={state.showErrorModal}
        onClose={() => setStateValue("showErrorModal", false)}
      ></ErrorModal>
    </DocuPointLayout>
  );
}

export { TenantsScreen };
