import React, { useEffect, useState } from "react";

import { DocuPointLayout } from "../../components/docupointLayout.js";
import { TenantHeader } from "./tenantHeader.js";
import { PlusIcon } from "@heroicons/react/outline";
import { ThemedButton } from "../../components/buttons/themedButton";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { Modal } from "../../components/modal.js";
import { TemplateDetails } from "./templates/templateDetails.js";
import { TemplateList } from "./templates/templateList.js";

function TenantTemplates() {
  const { path } = useRouteMatch();

  return (
    <>
      <DocuPointLayout>
        <TenantHeader location="Checklists" />
        <Switch>
          <Route path={`${path}/add`}>
            <TemplateDetails />
          </Route>
          <Route path={`${path}/:templateKey`}>
            <TemplateDetails />
          </Route>
          <Route path={path}>
            <TemplateList />
          </Route>
        </Switch>
      </DocuPointLayout>
    </>
  );
}

export { TenantTemplates };
