import React from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const BreadCrumb_Requests_Request = ({ customerRequestKey, accountName }) => {
  return BreadCrumb([
    { to: "/requests", name: "Requests" },
    { to: `/requests/${customerRequestKey}`, name: accountName },
  ]);
};

const BreadCrumb_Requests_Create = () => {
  return BreadCrumb([
    { to: "/requests", name: "Requests" },
    { to: `/requests/create`, name: "New Request" },
  ]);
};

const BreadCrumb_Accounts_Account = ({ account }) => {
  return BreadCrumb([
    { to: "/accounts", name: "Accounts" },
    { to: `/accounts/${account}`, name: account },
  ]);
};

const BreadCrumb = (navigations) => {
  return (
    <nav className="hidden sm:flex breadcrumbs" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-2">
        {" "}
        {navigations.map((navigation, index) => {
          return (
            <li key={navigation.name}>
              {index > 0 ? (
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="flex-shrink-0 h-4 w-4 text-gray-400 mr-1"
                    aria-hidden="true"
                  />{" "}
                  <div className="flex">
                    <Link to={navigation.to} className="breadcrumb">
                      {navigation.name}
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="flex">
                  <Link to={navigation.to} className="breadcrumb">
                    {navigation.name}
                  </Link>
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export {
  BreadCrumb,
  BreadCrumb_Requests_Request,
  BreadCrumb_Requests_Create,
  BreadCrumb_Accounts_Account,
};
