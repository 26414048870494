import React, { useContext, Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { ClipboardListIcon } from "@heroicons/react/solid";
import { DocRequestList } from "./docRequestList";
import { EmptyListPlaceholder } from "../../components/emptyListPlaceholder";

const CustomerRequest = ({
  title,
  message,
  docRequests,
  onAddFile,
  onRemoveFile,
  maxDocumentsPerRequest,
}) => {
  const onAddFileClick = ({ name, docKey, uploadedFilesCount }) => {
    if (onAddFile) {
      onAddFile({ name: name, docKey: docKey, uploadedFilesCount });
    }
  };

  const onRemoveFileClick = ({ name, docKey }) => {
    if (onRemoveFile) {
      onRemoveFile({ name: name, docKey: docKey });
    }
  };

  return (
    <div className="customer-request sm:border sm:rounded-lg w-full sm:px-2 py-4 mt-4">
      <Disclosure>
        <Disclosure.Button>
          <div className="sm:text-lg font-bold pb-4 flex flex-row px-3 sm:px-0">
            <div className="flex">
              <ClipboardListIcon className="h-5 w-5 mr-2 " aria-hidden="true" />

              {title}
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel static>
          <div className="text-sm px-3 sm:px-0">{message}</div>

          <div className="pt-4">
            {!docRequests || docRequests.length === 0 ? (
              <EmptyListPlaceholder message="No doc requests found" />
            ) : (
              <DocRequestList
                docRequests={docRequests || []}
                onAddFile={onAddFileClick}
                onRemoveFile={onRemoveFileClick}
                maxDocumentsPerRequest={maxDocumentsPerRequest}
              />
            )}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

const RequestList = ({
  requests,
  onAddFile,
  onRemoveFile,
  maxDocumentsPerRequest,
}) => {
  return (
    <Fragment>
      {requests
        .filter((ele) => ele.status !== "Canceled")
        .map((itm, idx) => {
          return (
            <CustomerRequest
              key={idx}
              onAddFile={onAddFile}
              onRemoveFile={onRemoveFile}
              maxDocumentsPerRequest={maxDocumentsPerRequest}
              {...itm}
            />
          );
        })}
    </Fragment>
  );
};

export { RequestList };
