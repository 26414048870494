import React, { useContext } from "react";
import { getLoginDetails } from "../../utils/utils.js";
import { CheckIcon, MailIcon, XIcon } from "@heroicons/react/solid";

const FileInfoButton = ({
  filename = "",
  onRemoveClick,
  dockey,
  removing = false,
  error = false,
  docFileStatus = "",
  docFileAcceptanceStatus = "",
  styleSettings = null,
}) => {
  if (styleSettings == null) {
    styleSettings = getLoginDetails().tenantInfo;
  }

  if (styleSettings == null) {
    styleSettings = { docFileColor: "#777777", docFileTextColor: "#333333" };
  }

  const onRemove = () => {
    if (onRemoveClick && !removing) {
      onRemoveClick(dockey);
    }
  };

  let displayName = filename;
  if (displayName.length > 35) {
    displayName = `${displayName.substr(0, 32)}...`;
  }

  const { docFileColor, docFileTextColor } = styleSettings;
  const color = error ? "red" : docFileTextColor;
  const submitted = docFileStatus === "Submitted";
  const accepted = docFileAcceptanceStatus === "Accepted";
  const rejected = docFileAcceptanceStatus === "Rejected";
  const dimmed = submitted && !rejected ? "opacity-50" : "";

  return (
    <div className="my-2 sm:my-4 mx-2 select-none">
      <span
        className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${dimmed}`}
        style={{
          backgroundColor: rejected ? "#eee" : docFileColor,
          color: rejected ? "#777" : color,
        }}
      >
        {submitted ? (
          <>
            {accepted && (
              <CheckIcon
                className="w-3 mr-1 inline-block rounded-full"
                style={{ backgroundColor: color, color: docFileColor }}
              ></CheckIcon>
            )}
            {rejected && (
              <XIcon className="w-3 mr-1 inline-block rounded-full text-white bg-red-900 opacity-100" />
            )}
            {!accepted && !rejected && (
              <MailIcon
                className="w-3 mr-1 inline-block"
                style={{ color: color }}
              ></MailIcon>
            )}
          </>
        ) : (
          ""
        )}
        <span title={filename}>{displayName}</span>

        {!submitted && (
          <div className="ml-2" onClick={onRemove}>
            {removing ? <SpinnerX /> : <ClickX color={color} />}
          </div>
        )}
      </span>
    </div>
  );
};

const SpinnerX = () => {
  return (
    <div>
      <div
        style={{ borderTopColor: "transparent" }}
        className="w-4 h-4 border-4 border-white-400 border-solid rounded-full animate-spin"
      />
    </div>
  );
};

const ClickX = ({ color }) => {
  return (
    <div className={`cursor-pointer`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill={color}
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export { FileInfoButton };
