import React, { Fragment, useCallback, useEffect, useState } from "react";
import { authInfo } from "../../components/authInfo";
import { STATUS_CODE } from "../../utils/api-client";
import { UserCircleIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils/utils";

const fetchRequestSubmissions = async (key) => {
  const url = `api/customerrequests/${key}/submissions`;
  const response = await fetch(url, {
    headers: authInfo.getAuthenticationHeaders(),
  });

  return response;
};

const useRequestSubmissions = (key) => {
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getRequestSubmissions = useCallback(async () => {
    setStatus("loading");
    const response = await fetchRequestSubmissions(key);

    if (response.status === STATUS_CODE.OK) {
      const responseData = await response.json();
      setData(responseData);
      setStatus("loaded");
    } else {
      console.log("came here");
      setStatus("error");
      setData(null);
      setError("Error in getting request submission");
    }
  }, [key]);

  useEffect(() => {
    getRequestSubmissions();
  }, [getRequestSubmissions]);

  return { status, error, data };
};

export default function Submissions(props) {
  const { requestKey, setError } = props;
  const { data, error, status } = useRequestSubmissions(requestKey);
  const [requestSubmissions, setRequestSubmissions] = useState(null);

  const handleShowAllDocs = (id) => {
    const submissions = requestSubmissions.map((requestSubmission) => ({
      ...requestSubmission,
      showAllDocs: requestSubmission.id === id,
    }));
    setRequestSubmissions(submissions);
  };

  const postDownloadRequest = (apiUrl) => {
    let form = document.createElement("form");
    const authenticationHeaders = authInfo.getAuthenticationHeaders();
    form.method = "post";
    form.action = apiUrl;
    form.innerHTML = "";

    for (var header in authenticationHeaders) {
      form.innerHTML += `
        <input type="hidden" name="x-header-${header}" value="${authenticationHeaders[header]}">`;
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  const handleDownloadDocument = (docFile) => {
    if (docFile.purged) {
      return;
    }
    postDownloadRequest(`/api/docfile/${docFile.key}/download`);
  };

  const handleDownloadAllDocuments = (submissionKey) => {
    postDownloadRequest(
      `/api/submission/${submissionKey}/download?requestKey=${requestKey}`
    );
  };

  useEffect(() => {
    if (status === "loaded" && !!data) {
      const submissions = data.map((d) => ({ ...d, showAllDocs: false }));
      setRequestSubmissions(submissions);
    } else if (status === "error") {
      setError({ message: error });
    }
  }, [status, data, error]);

  useEffect(() => {
    console.log(requestSubmissions);
  }, [requestSubmissions]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-full pt-8">
      {!requestSubmissions && status !== "error" ? (
        <h3 className="text-lg font-bold tracking-tight text-gray-700 text-center">
          This request does not have submitted documents yet...
        </h3>
      ) : (
        <div className="grid gap-y-4">
          {requestSubmissions?.map((requestSubmission) => (
            <div className="detailsSection">
              <div
                key={requestSubmission.id}
                className="flex text-sm text-gray-500 items-start"
              >
                <div className="flex-none pr-3">
                  <UserCircleIcon
                    className="h-10 w-10 text-gray-300"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="font-medium text-gray-900">
                    {requestSubmission.submission.signatureLegalName}
                  </h3>
                  <p>
                    <time>
                      {new Date(
                        requestSubmission.submission.submissionDateUtc
                      ).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </time>
                  </p>
                  {requestSubmission.submission.comments && (
                    <div className="mt-4 quote max-w-none text-gray-500">
                      {requestSubmission.submission.comments}
                    </div>
                  )}
                  <div className="mt-4 flex space-x-2 space-y-2 flex-wrap">
                    <div></div>
                    {requestSubmission.submission.docFiles.map(
                      (docFile, idx) => {
                        return (
                          (idx < 2 || requestSubmission.showAllDocs) && (
                            <button
                              type="button"
                              disabled={docFile.purged}
                              key={docFile.key}
                              className={`inlineFileItem ${
                                docFile.purged ? "purged" : ""
                              }`}
                              onClick={() => handleDownloadDocument(docFile)}
                            >
                              <span
                                className={`${
                                  docFile.docFileStatus === "Declined"
                                    ? "line-through"
                                    : ""
                                }`}
                              >
                                {docFile.filename}
                              </span>

                              {docFile.docFileStatus === "Declined" && (
                                <span className="font-semibold ml-1">
                                  (Declined)
                                </span>
                              )}
                              {docFile.purged && (
                                <span className="font-semibold ml-1">
                                  (Purged)
                                </span>
                              )}
                            </button>
                          )
                        );
                      }
                    )}
                    {requestSubmission.submission.docFiles.length > 2 &&
                      !requestSubmission.showAllDocs && (
                        <button
                          type="button"
                          onClick={() =>
                            handleShowAllDocs(requestSubmission.id)
                          }
                          className="inlineFileItem"
                        >
                          {"+" +
                            (requestSubmission.submission.docFiles.length - 2) +
                            " more"}
                        </button>
                      )}
                  </div>
                </div>
                <div>
                  {!requestSubmission.submission.docFiles.every(
                    (d) => d.docFileStatus === "Declined"
                  ) && (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="itemMenuButton">
                          <span className="sr-only">Open options</span>
                          <DotsVerticalIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() =>
                                    handleDownloadAllDocuments(
                                      requestSubmission.submission.id
                                    )
                                  }
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm w-full text-left"
                                  )}
                                >
                                  Download All
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
