import React, { useEffect, useState } from "react";
import { getWebAuth } from "../../components/authInfo";
import { useHistory } from "react-router-dom";
import { getLoginDetails, saveLoginDetails } from "../../utils/utils";
import { authInfo } from "../../components/authInfo";
import { connectIdentity } from "./loginHelper";

const AuthorizeScreen = () => {
  const history = useHistory();
  const [connecting, setConnecting] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const finishLogin = async (authResult) => {
    const loginDetails = getLoginDetails();
    const loginStateJson = authResult.state;
    loginDetails.bearer = authResult.accessToken;
    loginDetails.hasLoggedIn = true;
    saveLoginDetails(loginDetails);
    authInfo.isAuthenticated = true;
    if (loginStateJson && loginStateJson.indexOf("appUserKey") >= 0) {
      const loginState = JSON.parse(loginStateJson);
      if (loginState.mode == "connect") {
        setConnecting(true);
        var connected = await connectIdentity(
          loginState.appUserKey,
          loginDetails.identityProviderName
        );
        setConnecting(false);
        if (connected) {
          history.push("/");
        } else {
          setErrorOccurred(true);
        }
      }
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    const webAuth = getWebAuth();
    webAuth.parseHash(
      { hash: window.location.hash },
      async (err, authResult) => {
        if (err) {
          console.log(err);
        } else {
          finishLogin(authResult);
        }
      }
    );
  });

  return (
    <div>
      {connecting && <div>Connecting your account...</div>}
      {errorOccurred && <div>An error occurred connecting your account</div>}
    </div>
  );
};

export { AuthorizeScreen };
