import React, { Fragment, useEffect, useState } from "react";
import { RefreshIcon } from "@heroicons/react/outline";
import { ThemedButton } from "./buttons/themedButton";
import { ErrorFallback } from "./errorFallback";

function PageError({ error }) {
  return (
    <div>
      <div className="flex justify-center">
        <ErrorFallback error={error} />
      </div>
      <div className="flex justify-center mt-10">
        <div>
          <ThemedButton
            onClick={() => {
              window.location.reload();
            }}
          >
            <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
            Try again
          </ThemedButton>
        </div>
      </div>
    </div>
  );
}

export { PageError };
