import React from "react";
import { LockOpenIcon } from "@heroicons/react/solid";
import { ThemedButton } from "../../components/buttons/themedButton";
import { useHistory } from "react-router-dom";
import { bankpointLogo, getLoginDetails } from "../../utils/utils";

const LogoutScreen = () => {
  const loginDetails = getLoginDetails();
  const tenant = loginDetails != null ? loginDetails.tenantInfo : null;
  const history = useHistory();
  const logoUrl = tenant ? tenant.logoUrl : bankpointLogo;
  const tenantName = tenant
    ? tenant.displayTenantName
      ? tenant.name
      : ""
    : "DocuPoint";

  const onLogin = () => {
    history.push("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8 bg-white shadow-lg p-8">
        <div>
          <img
            className="mx-auto max-h-32 w-auto"
            src={logoUrl}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {tenantName}
          </h2>
          <div className="mt-6 text-center text-xl text-gray-700">
            <p>You have successfully logged out.</p>
          </div>
        </div>
        {loginDetails?.userType != "Account" && (
          <div className="mt-8 space-y-6">
            <div>
              <ThemedButton onClick={onLogin}>
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockOpenIcon className="h-5 w-5 " aria-hidden="true" />
                </span>
                <span className="text-lg">Log In</span>
              </ThemedButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { LogoutScreen };
