import React from "react";
import styles from "./index.module.css";
import { CogIcon } from "@heroicons/react/solid";

function SegmentedProgressMeter({ segments, percentFilled }) {
  const segmentsFilled = Math.floor(segments * percentFilled);

  return (
    <div className="segmentedProgressMeter" width="50">
      {Array.apply(0, Array(10)).map(function (x, i) {
        return (
          <div
            key={`spm_${i}`}
            className={`segment ${i < segmentsFilled ? "filled" : ""}`}
          ></div>
        );
      })}
    </div>
  );
}

export { SegmentedProgressMeter };
