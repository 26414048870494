import { authInfo } from "../components/authInfo";
import PQueue from "p-queue";

const STATUS_CODE = {
  OK: 200,
  NOTFOUND: 404,
};

async function postJson(url, data, method) {
  return await fetch(url, {
    method: method ? method : "POST",
    headers: {
      ...{ "Content-Type": "application/json" },
      ...authInfo.getAuthenticationHeaders(),
    },
    body: data ? JSON.stringify(data) : null,
  });
}

/**
 * Upload a set of files
 */
async function uploadFiles(
  docKey,
  files,
  maxFileSizeString,
  cancellationToken,
  onProgress,
  onComplete
) {
  const queue = new PQueue({ concurrency: 3 });
  let errors = false;

  files.forEach((file) => {
    queue.add(() => {
      if (cancellationToken != null && cancellationToken.isCancelled) {
        return;
      }

      return uploadDocFile(docKey, file, maxFileSizeString, onProgress).then(
        () => {},
        () => {
          errors = true;
        }
      );
    });
  });

  queue.on("idle", () => {
    if (errors) {
      console.error(`Error uploading files`);
      onComplete({
        successfullyUploaded: false,
        message: "Error uploading files",
      });
    } else {
      onComplete({ successfullyUploaded: true });
    }
  });
}

/**
 * Workhorse api call which gets the entire account tree structure which is presented on screen
 */
async function getAccountDetails() {
  const url = `/api/Accounts/details`;
  return fetch(url, { headers: authInfo.getAuthenticationHeaders() });
}

async function getAppConfig() {
  const url = `/api/Config/AppConfig`;
  return fetch(url, { headers: authInfo.getAuthenticationHeaders() });
}

async function revokeToken() {
  return postJson("/logout");
}
/**
 * Upload an individual DocFile. Need to use raw HMLHttpRequest here, rather than fetch,
 * in order to get support for the percent upload update events
 */
function uploadDocFile(docKey, fileItem, maxFileSizeString, onProgress) {
  const url = `/api/docfile/${docKey}`;
  const formData = new FormData();
  formData.append("formFile", fileItem.file, fileItem.file.name);

  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();

    req.upload.addEventListener("progress", (evt) => {
      if (evt.lengthComputable) {
        let percentage = (evt.loaded / evt.total) * 100;
        onProgress({
          file: fileItem,
          status: "pending",
          percentage: percentage,
        });
      }
    });

    req.upload.addEventListener("load", (evt) => {
      onProgress({ file: fileItem, status: "done", percentage: 100 });
    });

    req.upload.addEventListener("error", (evt) => {
      onProgress({ file: fileItem, status: "error" });
      reject(req.response);
    });

    // needed to catch low level send errors, such as file too large
    // the reject call ensures that the upload error event listener gets an opportunity
    // to handle this.
    req.onreadystatechange = () => {
      if (req.readyState === XMLHttpRequest.DONE && req.status !== 200) {
        let message = "An error occurred processing this file.";
        switch (req.status) {
          case 413:
            message =
              "This file exceeds the size limit of " + maxFileSizeString + ".";
            break;
          case 404:
            if (req.response && req.response.indexOf("virus") >= 0) {
              message = "This file has been flagged for suspected malware.";
            } else {
              message = "An unknown error occurred uploading this file.";
            }
            break;
          default:
            let obj = tryParseJSONObject(req.response);
            if (!obj) {
              message = req.response;
            } else {
              if (obj.errors) {
                try {
                  let errMsg = obj.errors[Object.keys(obj.errors)[0]][0];
                  if (errMsg.includes("body length limit")) {
                    message = "This file exceeds the size limit.";
                  }
                } catch (ex) {}
              }
            }
            break;
        }

        onProgress({
          file: fileItem,
          status: "error",
          percentage: 100,
          message: message,
        });
        reject(req.response);
      }

      if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
        resolve(req.response);
      }
    };

    req.open("POST", url);
    Object.entries(authInfo.getAuthenticationHeaders()).map(([key, value]) => {
      req.setRequestHeader(key, value);
    });
    //req.setRequestHeader("Authorization", authInfo.bearerString())
    req.send(formData);
  });
}

function tryParseJSONObject(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}
}

// convert signin link to the corresponding account email and tenant key
async function fetchLinkDetails(linkval) {
  const response = await fetch(`/linkInfo?link=${linkval}`);
  if (!response.ok) {
    throw Error(`Invalid link specified`);
  }
  const data = await response.json();
  return data;
}

async function fetchTenantDetails(tenantid) {
  const response = await fetch(`/tenantInfo?key=${tenantid}`);
  if (!response.ok) {
    throw Error(`Invalid tenant key specified`);
  }

  return await response.json();
}

const postDownloadRequest = (apiUrl) => {
  let form = document.createElement("form");
  const authenticationHeaders = authInfo.getAuthenticationHeaders();
  form.method = "post";
  form.action = apiUrl;
  form.innerHTML = "";

  for (var header in authenticationHeaders) {
    form.innerHTML += `
        <input type="hidden" name="x-header-${header}" value="${authenticationHeaders[header]}">`;
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export {
  postJson,
  uploadFiles,
  fetchLinkDetails,
  getAccountDetails,
  fetchTenantDetails,
  getAppConfig,
  tryParseJSONObject,
  revokeToken,
  postDownloadRequest,
  STATUS_CODE,
};
