import React from "react";
import { setTenantContext } from "../../utils/utils";
import { TenantSettingsScreen } from "./tenantSettingsScreen";

function AddTenantScreen() {
  setTenantContext(null);

  return <TenantSettingsScreen />;
}

export { AddTenantScreen };
