import React, { Fragment } from "react";
import styles from "./index.module.css";
import { CogIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils/utils";

function ItemActions({ actions }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="itemMenuButton">
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="h-4 w-4" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {actions.map((itm, idx) => (
              <Menu.Item key={itm.name}>
                {({ active }) => (
                  <button
                    onClick={itm.disabled ? null : itm.execute}
                    className={classNames(
                      itm.disabled
                        ? "text-gray-400 cursor-default"
                        : "text-gray-700",
                      active && !itm.disabled
                        ? "bg-gray-100 text-gray-900"
                        : "",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                  >
                    {itm.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export { ItemActions };
