import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Loading } from "../components/loading";
import { authInfo } from "../components/authInfo";
import { getLoginDetails } from "../utils/utils";

const HomeScreen = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (loaded && authInfo.isAuthenticated) {
    const loginDetails = getLoginDetails();
    if (loginDetails.userType == "AppUser") {
      return <Redirect to={"/requests"} />;
    } else {
      return <Redirect to={"/account"} />;
    }
  }

  // Must let the page finish loading at least once for
  // the login cookies to be saved
  return loaded ? <Redirect to={"/requests"} /> : <Loading />;
};

export { HomeScreen };
