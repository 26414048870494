import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { DocuPointLayout } from "../../components/docupointLayout.js";
import { getLoginDetails, saveLoginDetails } from "../../utils/utils.js";
import { AppUsersScreen } from "../users/appUsersScreen.js";
import { GlobalHeader } from "./globalHeader.js";

function GlobalUsersScreen() {
  return (
    <DocuPointLayout>
      <GlobalHeader location="Users" />
      <AppUsersScreen global={true} />
    </DocuPointLayout>
  );
}

export { GlobalUsersScreen };
