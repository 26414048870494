import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";
import { ThemedButton } from "../../components/buttons/themedButton";
import { bankpointLogo } from "../../utils/utils";
import { startConnectIdentity } from "./loginHelper";

const InvitationScreen = () => {
  const [loading, setLoading] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(null);
  const { key } = useParams();
  const history = useHistory();

  const handleNext = async () => {
    setLoading(true);
    const resp = await fetch(`/api/invitations/${key}`);

    if (resp.ok) {
      const data = await resp.json();
      startConnectIdentity(
        data.emailAddress,
        data.appUserKey,
        data.loginConfig,
        history
      );
    } else {
      setLoading(false);
      setErrorOccurred(true);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="flex items-start justify-center py-12 px-4 sm:min-h-screen sm:items-center sm:bg-gray-50 sm:px-6 lg:px-8">
      <div className="max-w-xl w-full space-y-8 bg-white sm:shadow-lg p-8">
        <img
          className="mx-auto max-h-32 w-auto"
          src={bankpointLogo}
          alt="DocuPoint"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          DocuPoint
        </h2>

        <div>
          <div>
            Welcome to DocuPoint! Please click the link below to log in to your
            account.
          </div>
          <div className={`mt-2 text-red-600 ${errorOccurred ? "" : "hidden"}`}>
            Sorry, it looks like we're having trouble connecting your account.
            Please try again.
          </div>
          <div className="mt-4">
            <ThemedButton onClick={handleNext} disabled={loading} type="submit">
              <span className="text-lg">Next</span>
            </ThemedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export { InvitationScreen };
