import React, { Fragment, useEffect, useState } from "react";
import { RequestList } from "./customerRequest";
import { ErrorFallback } from "../../components/errorFallback";
import { UploadModal } from "../upload";
import { SignatureModal } from "./signatureModal";
import { TermsModal } from "./termsModal";
import { Redirect, useHistory } from "react-router-dom";
import { getAccountDetails, getAppConfig } from "../../utils/api-client";
import { Placeholder } from "../../components/placeholder";
import { authInfo } from "../../components/authInfo";
import { ThemedButton } from "../../components/buttons/themedButton";
import { MailIcon, RefreshIcon } from "@heroicons/react/solid";
import styles from "./index.css";

const AccountScreen = () => {
  const [state, setState] = useState({
    accountKey: null,
    status: "loading",
    requests: null,
    error: null,
    tenantKey: null,
    docKey: null, // needed for the upload dialog
    docName: null, // needed for the upload dialog
    uploadedFilesCount: null, // needed for the upload dialog
    showModal: false,
    showSignatureModal: false,
    maxFileSizeString: null,
    signatureError: null,
    showTermsModal: false,
    termsVersion: null,
    maxDocumentsPerRequest: 0,
  });

  const history = useHistory();

  const { requests, status, error } = { ...state };

  useEffect(() => {
    const getCustomerRequests = async () => {
      const accountDetailsResponse = await getAccountDetails();
      const appConfigResponse = await getAppConfig();

      if (accountDetailsResponse.ok && appConfigResponse.ok) {
        let data = await accountDetailsResponse.json();
        if (data.customerRequests.length === 0) {
          history.push("/no-account");
          return;
        }

        const appConfig = await appConfigResponse.json();

        let updates = {
          accountKey: data.key,
          status: "loaded",
          requests: data.customerRequests,
          maxFileSizeString: appConfig.maxFileSizeString,
          showTermsModal: !data.termsAccepted,
          termsVersion: data.currentTermsVersion,
          maxDocumentsPerRequest: data.maxDocumentsPerRequest,
        };

        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await accountDetailsResponse.text();
        console.log(`Error loading account: ${message}`);

        let updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading your account details. Please try again, or contact your financial institution if you continue to receive this message."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
        //history.push('/no-account');
      }
    };

    getCustomerRequests();
  }, [setState, history]);

  const onAddFile = ({ name, docKey, uploadedFilesCount }) => {
    setState((prevState) => ({
      ...prevState,
      docName: name,
      docKey: docKey,
      uploadedFilesCount,
      showModal: true,
    }));
  };

  const onSignAndSend = () => {
    setState((prevState) => ({ ...prevState, showSignatureModal: true }));
  };

  const onCancelSignature = () => {
    setState((prevState) => ({ ...prevState, showSignatureModal: false }));
  };

  const refreshCustomerRequest = async () => {
    const resp = await getAccountDetails();
    if (resp.ok) {
      let data = await resp.json();
      setState((prevState) => ({
        ...prevState,
        requests: data.customerRequests,
        showModal: false,
      }));
    } else {
      const message = await resp.text();
      setState((prevState) => ({
        ...prevState,
        status: "error",
        error: new Error(message),
        showModal: false,
      }));
    }
  };

  const onRemoveFile = ({ docKey }) => {
    // set the removing state to true to trigger animation while deleting
    const modifyDocFile = ({ docKey, newState }) => {
      requests.forEach((req) => {
        req.docRequests.forEach((itm) => {
          itm.docFiles.forEach((afile) => {
            if (afile.key === docKey) {
              for (const aprop in newState) {
                afile[aprop] = newState[aprop];
              }
            }
          });
        });
      });
    };

    // issue the api call to remove the file and clear the removing flag
    const deleteDocFile = async (docKey) => {
      const url = `/api/docfile/${docKey}`;
      let resp = await fetch(url, {
        method: "DELETE",
        headers: authInfo.getAuthenticationHeaders(),
      });
      if (resp.ok) {
        refreshCustomerRequest();
      } else {
        const stateCopy = { ...state };
        modifyDocFile({
          envelope: stateCopy.envelope,
          docKey: docKey,
          newState: { removing: false, error: true },
        });
        setState(stateCopy);
      }
    };

    const stateCopy = { ...state };
    modifyDocFile({ docKey: docKey, newState: { removing: true } });
    setState(stateCopy);
    deleteDocFile(docKey);
  };

  const onUploadComplete = () => {
    setState((prevState) => ({ ...prevState, showModal: false }));
    refreshCustomerRequest();
  };

  const onSign = async ({ comments, legalName }) => {
    const url = `/api/submission`;
    let data = { comments: comments, legalName: legalName };

    // TODO move the api call to the api-file
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        ...{ "Content-Type": "application/json" },
        ...authInfo.getAuthenticationHeaders(),
      },
      body: JSON.stringify(data),
    });

    if (resp.ok) {
      setState((prevState) => ({ ...prevState, status: "signed" }));
    } else {
      let errorMessage = "An error occurred submitting these documents.";
      if (resp.status < 500) {
        errorMessage +=
          " An error occurred submitting these documents. If your comments or name includes non-alpha or numeric characters, you may want to rephrase them so that these characters are removed.";
      } else {
        errorMessage +=
          " Please try again, and contact your financial institution if you continue to receive this error.";
      }

      setState((prevState) => ({ ...prevState, signatureError: errorMessage }));
    }
  };

  const onTermsAccepted = async () => {
    setState((prevState) => ({ ...prevState, showTermsModal: false }));
    const url = `/api/terms/${state.termsVersion}/accept`;
    await fetch(url, {
      method: "POST",
      headers: {
        ...{ "Content-Type": "application/json" },
        ...authInfo.getAuthenticationHeaders(),
      },
    });
  };

  const checkForPendingFiles = () => {
    let hasPending = false;
    if (null == requests) {
      return;
    }

    requests.forEach((req) => {
      req.docRequests.forEach((docReq) => {
        docReq.docFiles.forEach((file) => {
          if (file.docFileStatus === "Received") {
            hasPending = true;
          }
        });
      });
    });
    return hasPending;
  };

  const reload = () => {
    window.location.reload();
  };

  const hasPending = checkForPendingFiles();

  return (
    <Fragment>
      <div className="flex flex-col">
        {status === "loading" ? (
          <Placeholder />
        ) : status === "signed" ? (
          <Redirect to={"/thankyou"} />
        ) : status === "error" ? (
          <div>
            <div className="flex justify-center">
              <ErrorFallback error={error} />
            </div>
            <div className="flex justify-center mt-10">
              <div>
                <ThemedButton onClick={reload}>
                  <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                  Try again
                </ThemedButton>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <RequestList
              onAddFile={onAddFile}
              onSignAndSend={onSignAndSend}
              onRemoveFile={onRemoveFile}
              requests={requests}
              maxDocumentsPerRequest={state.maxDocumentsPerRequest}
            />
            <div className="pt-5 flex justify-center">
              <div>
                <ThemedButton onClick={onSignAndSend} disabled={!hasPending}>
                  Send Documents
                  <MailIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                </ThemedButton>
              </div>
            </div>
            <UploadModal
              docKey={state.docKey}
              name={state.docName}
              show={state.showModal}
              onClose={onUploadComplete}
              maxFileSizeString={state.maxFileSizeString}
              maxAllowedDocuments={
                state.maxDocumentsPerRequest - state.uploadedFilesCount
              }
            />
            <SignatureModal
              show={state.showSignatureModal}
              errorMessage={state.signatureError}
              onCancel={onCancelSignature}
              onSign={onSign}
            />
            <TermsModal
              show={state.showTermsModal}
              onAccept={onTermsAccepted}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export { AccountScreen };
