import React, { Fragment, useState, useEffect } from "react";
import { ThemedButton } from "./buttons/themedButton";
import { Modal } from "./modal";

function ErrorModal({ show, onClose, errorMessage = null }) {
    if (!errorMessage) {
        errorMessage = "We're sorry. An error has occurred completing the operation you requested.";
    }

    return (
        <Modal show={show}>
            <div className="text-xl text-center font-bold pb-5 mb-5 border-b border-gray-200">
                Error
            </div>
            <div className="color-red-600">{errorMessage}</div>
            <div className="mt-8 flex">
                <ThemedButton onClick={onClose}>Close</ThemedButton>
            </div>
        </Modal>
    );
}

export { ErrorModal }