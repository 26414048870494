import React from "react";
import { Modal } from "./modal";
import { CancelButton, ThemedButton } from "./buttons/themedButton";

const ConfirmModal = (props) => {
  const { actions, children, onAction, show, title } = props;

  return (
    <Modal show={show}>
      <div className="text-xl text-center font-bold pb-5 mb-5 border-b border-gray-200">
        {title}
      </div>
      <div>{children}</div>
      <>
        {actions?.length > 0 && (
          <div className="mt-8 flex flex-row-reverse">
            {actions
              .sort((a, _) => (!!a.primary ? -1 : 0))
              .map((action) => (
                <div className="mr-2" key={action.text}>
                  {!!action.primary ? (
                    <ThemedButton
                      fitted={true}
                      onClick={() => onAction(action.text)}
                    >
                      {action.text}
                    </ThemedButton>
                  ) : (
                    <CancelButton
                      fitted={true}
                      onClick={() => onAction(action.text)}
                    >
                      {action.text}
                    </CancelButton>
                  )}
                </div>
              ))}
          </div>
        )}
      </>
    </Modal>
  );
};

export default ConfirmModal;
