import React, { Fragment } from 'react'
import styles from './index.module.css'

function Validator({ fieldName, errorsList }) {
    const error = errorsList[fieldName];

    return (
        <Fragment>
            {
                error ? <div className='text-red-600'>{error}</div> : ''
            }
        </Fragment>
    )
}

export { Validator }