import { PlusCircleIcon } from "@heroicons/react/outline";
import React, { useRef, useState, Fragment } from "react";

const Dropzone = ({
    children,
    onFilesAdded,
    showControl,
    linkColor = "#000000",
    maxFileSizeString,
    allowOfficeDocuments
}) => {
    const [, setHighlight] = useState(false);
    const fileInput = useRef(null);

    const openFileDialog = () => {
        fileInput.current.click();
    }

    const fileListToArray = (list) => {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array;
    }

    const filesAdded = (evt) => {
        let files = fileListToArray(evt.target.files);
        onFilesAdded(files);
    }

    const onDragOver = (evt) => {
        evt.preventDefault();
        setHighlight(true);
    }

    const onDragLeave = (evt) => {
        evt.preventDefault();
        setHighlight(false);
    }

    const onDrop = (evt) => {
        evt.preventDefault();
        let files = fileListToArray(evt.dataTransfer.files);
        onFilesAdded(files);
        setHighlight(false);
    }

    return (
        <div className="max-w-lg sm:p-6 sm:border-2 border-gray-300 border-dashed sm:rounded-md"
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            {
                showControl ?
                    <DefaultUploadControl linkColor={linkColor} openFileDialog={openFileDialog}
                        maxFileSizeString={maxFileSizeString} allowOfficeDocuments={allowOfficeDocuments} />
                    :
                    <div>
                        <div>
                            {children}
                        </div>
                        <div className='mt-4 flex content-start'>
                            <PlusCircleIcon className='w-4 h-4 mr-1 mt-1' />
                            <label
                                onClick={openFileDialog}
                                className="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                                <span style={{ color: linkColor }}>Add more files</span>
                            </label>
                        </div>
                    </div>
            }

            <input id="file-upload" ref={fileInput} name="file-upload" multiple type="file" className="sr-only"
                onChange={filesAdded}
            />
        </div>
    )
}

const DefaultUploadControl = ({ openFileDialog, linkColor, maxFileSizeString, allowOfficeDocuments }) => {
    return (
        <div className="space-y-1 text-center">
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
            >
                <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <div className="text-sm text-gray-600 text-center">
                <label
                    onClick={openFileDialog}
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                    <span style={{ color: linkColor }}>Upload a file</span>
                </label>
                <span className="pl-1">or drag and drop</span>
            </div>
            <p className="text-xs text-gray-500">{allowOfficeDocuments ? "MS Office," : ""} PDF or image files up to {maxFileSizeString}</p>
        </div>

    )
}

export { Dropzone }