import React, { Fragment, useEffect, useState, useRef } from "react";
import { Redirect, useHistory, useParams, Link } from "react-router-dom";
import styles from "./index.module.css";
import { ToggleButton } from "../../components/buttons/toggleButton";
import {
  CancelButton,
  ThemedButton,
} from "../../components/buttons/themedButton";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { postJson } from "../../utils/api-client";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { Validator } from "../../components/validator";
import { RefreshIcon } from "@heroicons/react/solid";
import { FileInfoButton } from "../account/fileInfoButton";
import {
  toastSuccess,
  checkRequiredFields,
  toastError,
  getLoginDetails,
  saveLoginDetails,
  getProfile,
} from "../../utils/utils.js";
import { tryParseJSONObject } from "../../utils/api-client";
import { TenantHeader } from "./tenantHeader";
import { DocuPointLayout } from "../../components/docupointLayout";

function TenantSettingsScreen() {
  const history = useHistory();
  const [state, setState] = useState({
    status: "loading",
    error: null,
    validationErrors: {},
    showErrorModal: false,
    modalErrorMessage: null,
    saving: false,
    originalName: "",
    originalLogoId: 0,
    fields: {
      name: "",
      bankPointInstanceKey: "",
      brandingButtonColor: "#333333",
      brandingDocFileTextColor: "#666666",
      brandingDocFileColor: "#cccccc",
      logoId: 0,
      emailInvitationText:
        "Your financial institution has requested that you provide supporting documents to further your loan application.",
      emailReminderText:
        "You have one or more outstanding document requests from your financial institution.",
      emailChangeText:
        "The documents being requested from your financial institution have changed.",
      signatureText:
        "Electronic signatures have the same legal effect as ink signatures on hard copies. By typing my name in the following box I certify the included statements and documents to be true and correct, to the best of my knowledge. \
\
Additionally, I certify that I have read and agree to the terms and conditions of this application and of the policy documents.",
      thankYouText:
        "Thank you for providing supporting documents for your application. A representative from your financial institution will contact you regarding next steps.",
      allowOfficeDocuments: false,
      defaultRequestTitle: "",
      defaultRequestDescription: "",
      displayTenantName: true,
    },
  });

  const [unauthorized, setUnauthorized] = useState(false);
  const imageTypes = [".jpeg", ".jpg", ".gif", ".png"];
  const imageTypesString = imageTypes.join(", ");
  const loginDetails = getLoginDetails();
  const tenantKey = loginDetails.tenantInfo
    ? loginDetails.tenantInfo.key
    : null;
  const fileInput = useRef(null);

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = function (event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const fields = state.fields;
    fields[name] = value;
    setStateValue("fields", fields);
  };

  const handleToggleChange = function (field) {
    const fields = state.fields;
    fields[field] = !fields[field];
    setStateValue("fields", fields);
  };

  const validateForm = function () {
    const values = state.fields;
    const validationErrors = {};
    checkRequiredFields(
      values,
      [
        "name",
        "brandingButtonColor",
        "brandingDocFileTextColor",
        "brandingDocFileColor",
        "logoId",
        "emailInvitationText",
        "emailReminderText",
        "emailChangeText",
        "signatureText",
        "thankYouText",
      ],
      validationErrors
    );

    const valid = Object.keys(validationErrors).length == 0;

    setState((prevState) => ({
      ...prevState,
      showErrorModal: !valid,
      validationErrors: validationErrors,
      modalErrorMessage: valid
        ? null
        : "The information you have entered was not able to be submitted. Please review the errors listed.",
    }));

    return valid;
  };

  const handleUploadLogo = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleFileSelected = (evt) => {
    const file = evt.target.files[0];
    const extension = file.name.substring(file.name.lastIndexOf("."));

    if (imageTypes.indexOf(extension.toLowerCase()) == -1) {
      toastError("Please choose an image file: " + imageTypesString);
      return;
    }

    uploadLogo(file, "", () => {}).then(
      (resp) => {
        const respData = tryParseJSONObject(resp);
        const fields = state.fields;
        fields["logoId"] = respData.logoId;
        setStateValue("fields", fields);
      },
      (resp) => {
        toastError("An error occurred uploading the image file.");
      }
    );
  };

  function uploadLogo(file, maxFileSizeString, onProgress) {
    const url = `/api/logos`;
    const formData = new FormData();
    formData.append("formFile", file, file.name);

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          let percentage = (evt.loaded / evt.total) * 100;
          onProgress({
            file: file.name,
            state: "pending",
            percentage: percentage,
          });
        }
      });

      req.upload.addEventListener("load", (evt) => {
        onProgress({ file: file.name, state: "done", percentage: 100 });
      });

      req.upload.addEventListener("error", (evt) => {
        onProgress({ file: file.name, state: "error" });
        reject(req.response);
      });

      // needed to catch low level send errors, such as file too large
      // the reject call ensures that the upload error event listener gets an opportunity
      // to handle this.
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE && req.status !== 200) {
          let message = "An error occurred processing this file.";
          switch (req.status) {
            case 413:
              message =
                "This file exceeds the size limit of " +
                maxFileSizeString +
                ".";
              break;
            default:
              let obj = tryParseJSONObject(req.response);
              if (!obj) {
                message = req.response;
              } else {
                if (obj.errors) {
                  try {
                    let errMsg = obj.errors[Object.keys(obj.errors)[0]][0];
                    if (errMsg.includes("body length limit")) {
                      message = "This file exceeds the size limit.";
                    }
                  } catch (ex) {}
                }
              }
              break;
          }

          onProgress({
            file: file.name,
            state: "error",
            percentage: 100,
            message: message,
          });
          reject(req.response);
        }

        if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
          resolve(req.response);
        }
      };

      req.open("POST", url);
      Object.entries(authInfo.getAuthenticationHeaders()).map(
        ([key, value]) => {
          req.setRequestHeader(key, value);
        }
      );
      //req.setRequestHeader("Authorization", authInfo.bearerString())
      req.send(formData);
    });
  }

  const handleSave = async function () {
    if (!validateForm()) {
      return;
    }

    const data = state.fields;
    setStateValue("saving", true);
    let url = "/api/tenantsettings";
    let method = "POST";

    if (tenantKey) {
      method = "PUT";
    }
    const resp = await postJson(url, data, method);
    setStateValue("saving", false);

    if (resp.ok) {
      const { tenantInfo } = await getProfile(true);
      if (tenantInfo) {
        const loginDetails = getLoginDetails();
        loginDetails.tenantInfo = tenantInfo;
        saveLoginDetails(loginDetails);
      }
      toastSuccess("Changes saved!");
      if (tenantKey) {
        reload();
      } else {
        history.push("/");
      }
    } else {
      setStateValue("showErrorModal", true);
    }
  };

  const reload = () => {
    window.location.reload();
  };

  const closeErrorModal = () => {
    setState((prevState) => ({
      ...prevState,
      showErrorModal: false,
      modalErrorMessage: null,
    }));
  };

  const handleResetFrameBrandingColor = () => {
    const fields = state.fields;
    fields.brandingBodyFrameColor = "#F3F4F6";
    fields.brandingMainSectionColor = "#FFFFFF";
    fields.brandingHeaderColor = "#1F2937";
    setStateValue("fields", fields);
  };

  useEffect(() => {
    async function loadData() {
      const url = `/api/tenantsettings`;
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (response.ok) {
        const data = await response.json();
        const updates = {
          fields: data,
          status: "loaded",
          originalName: data.name,
          originalLogoId: data.logoId,
        };

        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        if (response.status == 403) {
          setUnauthorized(true);
        } else {
          const message = await response.text();
          console.log(`Error loading tenant data: ${message}`);

          const updates = {
            status: "error",
            error: new Error(
              "We're sorry. There seems to be an issue loading this page."
            ),
          };
          setState((prevState) => ({ ...prevState, ...updates }));
        }
      }
    }

    if (tenantKey) {
      loadData();
    } else {
      const updates = {
        status: "loaded",
        originalName: "New Tenant",
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    }
  }, []);

  return (
    <DocuPointLayout unauthorized={unauthorized}>
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {tenantKey ? (
            <TenantHeader location="Settings" />
          ) : (
            <div className="text-lg font-medium mb-4">New Tenant</div>
          )}
          <form>
            <div className="bg-gray-100 p-4">
              <div className="formSection">
                <div className="formSectionLeft">
                  <div className="px-4 sm:px-0">
                    <h3 className="formSectionHeader">General Settings</h3>
                  </div>
                </div>

                <div className="formSectionRight">
                  <div className="formSectionRightPanel">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="name" className="formLabel">
                          Name
                        </label>
                        <input
                          type="text"
                          value={state.fields.name}
                          name="name"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="name"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="show-name" className="formLabel">
                          Display tenant name in the screen
                        </label>
                        <p className="formHelpText">
                          This will show the tenant Name in Welcome Page, Access
                          Code Notification, Document Portal Header etc.
                        </p>
                        <ToggleButton
                          checked={state.fields.displayTenantName}
                          onChange={(e) => {
                            handleToggleChange("displayTenantName");
                          }}
                        ></ToggleButton>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="show-name" className="formLabel">
                          Show BankPoint Branding
                        </label>
                        <p className="formHelpText">
                          This will show BankPoint's branding including logo,
                          links, etc.
                        </p>
                        <ToggleButton
                          checked={state.fields.showBankPointBranding}
                          onChange={(e) => {
                            handleToggleChange("showBankPointBranding");
                          }}
                        ></ToggleButton>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="bankPointInstanceKey"
                          className="formLabel"
                        >
                          BankPoint Instance Key
                        </label>
                        <input
                          type="text"
                          value={state.fields.bankPointInstanceKey}
                          name="bankPointInstanceKey"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="bankPointInstanceKey"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection mt-4">
                <div className="formSectionLeft">
                  <div className="px-4 sm:px-0">
                    <h3 className="formSectionHeader">Branding</h3>
                  </div>
                </div>

                <div className="formSectionRight">
                  <div className="formSectionRightPanel">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label className={`formLabel flex-none`}>
                          Logo (
                          <a
                            href=""
                            onClick={handleUploadLogo}
                            className="colored"
                          >
                            Change
                          </a>
                          )
                        </label>
                        <div>
                          <div
                            className={`${
                              state.fields.logoId > 0 ? "" : "hidden"
                            } flex text-sm`}
                          >
                            <div className="p-4">
                              <img
                                src={`/logos/${state.fields.logoId}`}
                                className="brandingLogo mb-4 mt-2 ml-auto mr-auto"
                              />
                              <div>Sample on white</div>
                            </div>
                            <div className="bg-gray-800 p-4 align-center">
                              <img
                                src={`/logos/${state.fields.logoId}`}
                                className="brandingLogo mb-4 mt-2 ml-auto mr-auto"
                              />
                              <div className="text-white">Sample on dark</div>
                            </div>
                          </div>
                          <div
                            className={state.fields.logoId > 0 ? "hidden" : ""}
                          >
                            <CancelButton
                              onClick={handleUploadLogo}
                              fitted={true}
                            >
                              Choose a Logo
                            </CancelButton>
                          </div>
                          <input
                            id="file-upload"
                            ref={fileInput}
                            name="file-upload"
                            multiple
                            type="file"
                            className="sr-only"
                            onChange={handleFileSelected}
                            accept={imageTypesString}
                          />
                          <Validator
                            fieldName="logoId"
                            errorsList={state.validationErrors}
                          ></Validator>
                        </div>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="brandingButtonColor"
                          className="formLabel"
                        >
                          Button Color
                        </label>
                        <input
                          type="text"
                          value={state.fields.brandingButtonColor}
                          name="brandingButtonColor"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="brandingButtonColor"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <ThemedButton
                          fitted={true}
                          settings={{
                            buttonColor: state.fields.brandingButtonColor,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Sample
                        </ThemedButton>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="brandingDocFileColor"
                          className="formLabel"
                        >
                          File Background Color
                        </label>
                        <input
                          type="text"
                          value={state.fields.brandingDocFileColor}
                          name="brandingDocFileColor"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="brandingDocFileColor"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="brandingDocFileTextColor"
                          className="formLabel"
                        >
                          File Text Color
                        </label>
                        <input
                          type="text"
                          value={state.fields.brandingDocFileTextColor}
                          name="brandingDocFileTextColor"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="brandingDocFileTextColor"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <FileInfoButton
                          filename="Sample.pdf"
                          styleSettings={{
                            docFileColor: state.fields.brandingDocFileColor,
                            docFileTextColor:
                              state.fields.brandingDocFileTextColor,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        ></FileInfoButton>

                        <FileInfoButton
                          filename="Submitted File.pdf"
                          docFileStatus="Submitted"
                          styleSettings={{
                            docFileColor: state.fields.brandingDocFileColor,
                            docFileTextColor:
                              state.fields.brandingDocFileTextColor,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        ></FileInfoButton>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="brandingHeaderColor"
                          className="formLabel"
                        >
                          Header Background Color
                        </label>
                        <input
                          type="color"
                          value={state.fields.brandingHeaderColor}
                          name="brandingHeaderColor"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="brandingHeaderColor"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="brandingMainSectionColor"
                          className="formLabel"
                        >
                          Main Section Background Color
                        </label>
                        <input
                          type="color"
                          value={state.fields.brandingMainSectionColor}
                          name="brandingMainSectionColor"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="brandingMainSectionColor"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="brandingBodyFrameColor"
                          className="formLabel"
                        >
                          Body Frame Color
                        </label>
                        <input
                          type="color"
                          value={state.fields.brandingBodyFrameColor}
                          name="brandingBodyFrameColor"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="brandingBodyFrameColor"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <ThemedButton
                          fitted={true}
                          settings={{
                            buttonColor: state.fields.brandingButtonColor,
                          }}
                          onClick={(e) => {
                            handleResetFrameBrandingColor();
                            e.preventDefault();
                          }}
                        >
                          Reset to default
                        </ThemedButton>
                      </div>

                      <div className="col-span-3 sm:col-span-2">
                        <label className="formLabel">Preview: </label>
                        <div
                          className="h-48 relative border shadow-lg"
                          style={{
                            backgroundColor:
                              state.fields.brandingMainSectionColor,
                          }}
                        >
                          <div
                            className="h-1/3 flex gap-2 p-2"
                            style={{
                              backgroundColor: state.fields.brandingHeaderColor,
                            }}
                          >
                            <div className="rounded-sm w-12 h-5 bg-white opacity-60"></div>
                          </div>
                          <div className=" absolute inset-0 flex items-center justify-center">
                            <div
                              className="rounded h-3/4 w-3/4 p-3 flex gap-1"
                              style={{
                                backgroundColor:
                                  state.fields.brandingBodyFrameColor,
                              }}
                            >
                              <div className="w-1/4"></div>
                              <div className="w-full bg-white rounded"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formSection mt-4">
                <div className="formSectionLeft">
                  <div className="px-4 sm:px-0">
                    <h3 className="formSectionHeader">Request Defaults</h3>
                  </div>
                </div>

                <div className="formSectionRight">
                  <div className="formSectionRightPanel">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="defaultRequestTitle"
                          className="formLabel"
                        >
                          Request Title
                        </label>
                        <p className="formHelpText">
                          Default request title for users who have not overriden
                          this setting on their profile
                        </p>
                        <input
                          type="text"
                          value={state.fields.defaultRequestTitle}
                          name="defaultRequestTitle"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="defaultRequestTitle"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="defaultRequestDescription"
                          className="formLabel"
                        >
                          Default Request Description
                        </label>
                        <p className="formHelpText">
                          Default request description for users who have not
                          overriden this setting on their profile
                        </p>
                        <textarea
                          type="text"
                          value={state.fields.defaultRequestDescription}
                          name="defaultRequestDescription"
                          onChange={handleInputChange}
                          className="w-full"
                          rows="4"
                        ></textarea>
                        <Validator
                          fieldName="defaultRequestDescription"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formSection mt-4">
                <div className="formSectionLeft">
                  <div className="px-4 sm:px-0">
                    <h3 className="formSectionHeader">Messaging</h3>
                  </div>
                </div>

                <div className="formSectionRight">
                  <div className="formSectionRightPanel">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="emailInvitationText"
                          className="formLabel"
                        >
                          Invitation Email Text
                        </label>
                        <p className="formHelpText">
                          Leading text that is included in the initial email to
                          a recipient of a request for documents
                        </p>
                        <textarea
                          value={state.fields.emailInvitationText}
                          name="emailInvitationText"
                          onChange={handleInputChange}
                          className="w-96 h-40"
                        />
                        <Validator
                          fieldName="emailInvitationText"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="emailChangeText" className="formLabel">
                          Changed Request Email Text
                        </label>
                        <textarea
                          value={state.fields.emailChangeText}
                          name="emailChangeText"
                          onChange={handleInputChange}
                          className="w-96 h-40"
                        />
                        <Validator
                          fieldName="emailChangeText"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="emailReminderText"
                          className="formLabel"
                        >
                          Followup Email Text
                        </label>
                        <textarea
                          value={state.fields.emailReminderText}
                          name="emailReminderText"
                          onChange={handleInputChange}
                          className="w-96 h-40"
                        />
                        <Validator
                          fieldName="emailReminderText"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="signatureText" className="formLabel">
                          Signature Text
                        </label>
                        <textarea
                          value={state.fields.signatureText}
                          name="signatureText"
                          onChange={handleInputChange}
                          className="w-96 h-40"
                        />
                        <Validator
                          fieldName="signatureText"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="thankYouText" className="formLabel">
                          Thank You Text
                        </label>
                        <textarea
                          value={state.fields.thankYouText}
                          name="thankYouText"
                          onChange={handleInputChange}
                          className="w-96 h-40"
                        />
                        <Validator
                          fieldName="thankYouText"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formSection mt-4">
                <div className="formSectionLeft">
                  <div className="px-4 sm:px-0">
                    <h3 className="formSectionHeader">Security</h3>
                  </div>
                </div>

                <div className="formSectionRight">
                  <div className="formSectionRightPanel">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="emailInvitationText"
                          className="formLabel"
                        >
                          Allow Office Documents
                        </label>
                        <p className="formHelpText">
                          Allow users to upload and submit Microsoft Office
                          documents (docx, xlsx, etc)
                        </p>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <ToggleButton
                            checked={state.fields.allowOfficeDocuments}
                            onChange={(e) => {
                              handleToggleChange("allowOfficeDocuments");
                            }}
                          ></ToggleButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="mt-4">
            <div className="inline-block w-40">
              <ThemedButton onClick={handleSave} disabled={state.saving}>
                Save
              </ThemedButton>
            </div>
            <div className="inline-block w-40 ml-2">
              <Link to="/">
                <CancelButton>Cancel</CancelButton>
              </Link>
            </div>
          </div>
        </div>
      )}
      <ErrorModal
        show={state.showErrorModal}
        errorMessage={state.modalErrorMessage}
        onClose={closeErrorModal}
      ></ErrorModal>
    </DocuPointLayout>
  );
}

export { TenantSettingsScreen };
