import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { DocuPointLayout } from "../../components/docupointLayout.js";
import { getLoginDetails, saveLoginDetails } from "../../utils/utils.js";
import { AppUserScreen } from "../users/appUserScreen.js";
import { GlobalHeader } from "./globalHeader.js";

function GlobalAppUserScreen() {
  const { appUserKey } = useParams();

  return (
    <DocuPointLayout>
      <GlobalHeader location="Users" />
      <AppUserScreen
        returnTo="/global/users"
        appUserKey={appUserKey}
        global={true}
      />
    </DocuPointLayout>
  );
}

export { GlobalAppUserScreen };
