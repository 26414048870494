import React, { useState } from "react";
import { LockClosedIcon, LockOpenIcon, MailIcon } from "@heroicons/react/solid";
import { getWebAuth } from "../../components/authInfo";
import styles from "./index.module.css";
import { ThemedButton } from "../../components/buttons/themedButton";
import { useHistory } from "react-router-dom";
import {
  bankpointLogo,
  getLoginDetails,
  startPasswordlessLogin,
} from "../../utils/utils";

const minPasscodeLength = 6; // Based on current auth0 behavior
const maxPasscodeLength = 10; // prevent unscrupulous behavior

/**
 * Prompt user for their secure login code which triggers the completion portion of the passwordless login.
 *
 * On success the client will be redirected to /authresult where the authtoken will be saved off and the user
 * will be sent to the /account page
 *
 */
const LoginCodeScreen = () => {
  const loginDetails = getLoginDetails();
  const tenant = loginDetails.tenantInfo;
  let deviceName = "your email address";

  if (loginDetails.authenticationType === "sms") {
    deviceName =
      "***-***-" +
      loginDetails.phoneNumber.substr(loginDetails.phoneNumber.length - 4);
  }

  const [state, setState] = useState({
    code: "",
    status: "sendCode",
    sending: false,
    errorMessage: null,
    forceNewAccessCode: false,
    validating: false,
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const history = useHistory();
  const loginState = history.location.state;

  const startPasswordlessCallback = (err, res) => {
    const updates = {
      sending: false,
    };

    if (err) {
      console.log(err);
      updates.error = true;
      updates.errorMessage = null;

      switch (err.code) {
        case "bad.phone_number":
        case "sms_provider_error":
          updates.errorMessage =
            "The phone number set up to receive access codes for this request is not a recognized mobile device.";
          break;
        default:
          updates.errorMessage = "An error occurred sending an access code.";
          break;
      }
    } else {
      updates.status = "enterCode";
    }

    setState((prevState) => ({ ...prevState, ...updates }));
  };

  const onLogin = (e) => {
    e.preventDefault();

    setState((prevState) => ({
      ...prevState,
      validating: true,
      errorMessage: null,
    }));

    var loginOptions = {
      connection: loginDetails.authenticationType,
      verificationCode: state.code,
      state: loginState,
    };

    switch (loginDetails.authenticationType) {
      case "email":
        loginOptions.email = loginDetails.email;
        break;
      case "sms":
        loginOptions.phoneNumber = loginDetails.phoneNumber;
        break;
    }

    getWebAuth().passwordlessLogin(loginOptions, function (err, res) {
      setState((prevState) => ({ ...prevState, validating: false }));

      if (err) {
        console.log(err);
        var update = {
          errorMessage: "The access code you entered is invalid.",
          forceNewAccessCode: false,
        };

        if (
          err.description ==
          "You've reached the maximum number of attempts. Please try to login again."
        ) {
          update.errorMessage =
            "You've exceeded the maximum number of attempts. Please generate a new access code and try again.";
          update.forceNewAccessCode = true;
        }

        if (
          err.description ==
          "The verification code has expired. Please try to login again."
        ) {
          update.errorMessage =
            "Your access code has expired. Please generate a new access code and try again.";
          update.forceNewAccessCode = true;
        }

        setState((prevState) => ({ ...prevState, ...update }));
      } else {
        console.log(`Login successful: ${res}`);
      }
    });
  };

  const onResendAccessCode = (e) => {
    e.preventDefault();
    setStateValue("sending", true);
    //Show the 'Sending' message for a second to give indication that it's doing something
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        forceNewAccessCode: false,
        errorMessage: null,
        code: "",
      }));
      sendCode();
    }, 1000);
  };

  const changeCode = (val) => {
    setState((prevState) => ({
      ...prevState,
      code: val.trim().substring(0, maxPasscodeLength),
    }));
  };

  const sendCode = () => {
    setStateValue("sending", true);
    try {
      startPasswordlessLogin(startPasswordlessCallback);
    } catch (ex) {
      history.push("/tenant-error");
    }
  };

  return (
    <form onSubmit={onLogin}>
      <div className="flex items-start justify-center py-12 px-4 sm:min-h-screen sm:items-center sm:bg-gray-50 sm:px-6 lg:px-8">
        <div className="max-w-xl w-full space-y-8 bg-white sm:shadow-lg p-8">
          <div>
            <img
              className="mx-auto max-h-32 w-auto"
              src={tenant ? tenant.logoUrl : bankpointLogo}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {tenant
                ? tenant.displayTenantName
                  ? tenant.name
                  : ""
                : "DocuPoint"}
            </h2>

            {state.status === "sendCode" ? (
              <div>
                <div className="my-6 text-center text-xl text-gray-700">
                  <p>
                    Welcome to the {tenant?.name} portal. To complete the login
                    process, an access code will be sent to {deviceName}.
                  </p>
                </div>
                {state.errorMessage ? (
                  <div className="my-6 text-red-600 text-center">
                    {state.errorMessage}
                  </div>
                ) : (
                  ""
                )}
                <ThemedButton
                  onClick={onResendAccessCode}
                  disabled={state.sending}
                >
                  <MailIcon className="w-5 mr-2" />
                  Send Access Code
                </ThemedButton>
              </div>
            ) : state.status === "enterCode" ? (
              <div className="mt-6 text-center text-xl text-gray-700">
                <p>
                  A login access code has been sent to {deviceName}. Enter the
                  code below to securely login.
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          {state.status === "enterCode" ? (
            <div className="mt-8 space-y-6">
              <div className="rounded-md -space-y-px">
                <div>
                  <input
                    id="code"
                    name="code"
                    type="text"
                    className={styles.accessCode}
                    value={state.code}
                    onChange={(evt) => changeCode(evt.target.value)}
                    autoFocus
                  />
                </div>
              </div>
              <div
                className={`text-red-600 text-center ${
                  state.errorMessage != null ? "" : "hidden"
                }`}
              >
                {state.errorMessage}
              </div>
              <div>
                <div
                  className={`${!state.sending ? "hidden" : ""} text-center`}
                >
                  Sending an access code...
                </div>
                <div
                  className={
                    state.forceNewAccessCode || state.sending ? "hidden" : ""
                  }
                >
                  <ThemedButton
                    onClick={onLogin}
                    disabled={
                      state.code.length < minPasscodeLength || state.validating
                    }
                    type="submit"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 " aria-hidden="true" />
                    </span>
                    <span className="text-lg">Login</span>
                  </ThemedButton>
                  <div className="text-center mt-4">
                    Didn't receive the code?{" "}
                    <a
                      className="colored"
                      href="#"
                      onClick={onResendAccessCode}
                    >
                      Try again.
                    </a>
                  </div>
                </div>
                <div
                  className={
                    !state.forceNewAccessCode || state.sending ? "hidden" : ""
                  }
                >
                  <ThemedButton onClick={onResendAccessCode} type="submit">
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <MailIcon className="h-5 w-5 " aria-hidden="true" />
                    </span>
                    <span className="text-lg">Re-Send Access Code</span>
                  </ThemedButton>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </form>
  );
};

export { LoginCodeScreen };
