import React from "react";
import { useState } from "react";
import { usePopper } from "react-popper";

export function Tooltip({ children, render, placement = "bottom", ...rest }) {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });
  const [isShowItems, setIsShowItems] = useState(false);

  if (render() == null) return children;

  return (
    <div {...rest} className="w-fit relative z-[99]" ref={setReferenceElement}>
      <div
        className="outline-none text-orange-500 hover:text-orange-700 inline"
        onMouseEnter={() => setIsShowItems(true)}
        onMouseLeave={() => setIsShowItems(false)}
      >
        {children}
      </div>
      {isShowItems && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="w-max bg-gray-700 text-white border rounded-lg px-2 py-2.5 text-sm">
            {render()}
          </div>
        </div>
      )}
    </div>
  );
}
export default Tooltip;
