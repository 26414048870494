import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BrowserRouter as Router, Link, useRouteMatch } from "react-router-dom";
import { ThemedButton } from "../../components/buttons/themedButton";
import { ItemActions } from "../../components/buttons/itemActions";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  RefreshIcon,
  SearchIcon,
  BeakerIcon,
  DownloadIcon,
  CheckIcon,
  MailIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/solid";
import { DocuPointLayout } from "../../components/docupointLayout";
import { getFormattedDate, toQueryString } from "../../utils/utils";
import dayjs from "dayjs";

const ListResults = ({
  listResults,
  isLoading,
  backEnabled,
  nextEnabled,
  onSort,
  onPreviousPage,
  onNextPage,
}) => {
  return (
    <div>
      <table className={`dataTable w-full mt-4 ${isLoading ? "loading" : ""}`}>
        <thead>
          <tr>
            <ColumnHeader
              name="Account"
              columnId="Account"
              listResults={listResults}
              onClick={onSort}
            />
            <ColumnHeader
              name="Requests"
              columnId="RequestsCount"
              listResults={listResults}
              onClick={onSort}
            />
            <ColumnHeader
              name="Last Requested"
              columnId="LastDateCreatedUtc"
              listResults={listResults}
              onClick={onSort}
            />
          </tr>
        </thead>
        <tbody>
          {listResults.pageData.map((itm, idx) => (
            <AccountItem itm={itm} key={`account_${idx}`} />
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <div className="pageCounts">
          {listResults.pageStart}-{listResults.pageEnd} of{" "}
          {listResults.totalCount}
        </div>
        <span
          className={`pageDirection ${backEnabled === false ? "disabled" : ""}`}
          onClick={onPreviousPage}
        >
          <ChevronLeftIcon />
        </span>
        <span
          className={`pageDirection ${nextEnabled === false ? "disabled" : ""}`}
          onClick={onNextPage}
        >
          <ChevronRightIcon />
        </span>
      </div>
    </div>
  );
};

const AccountItem = ({ itm, idx }) => {
  return (
    <tr className="text-sm">
      <td>
        <Link
          className="font-medium colored"
          to={`/accounts/${encodeURIComponent(itm.account)}`}
        >
          {itm.account}
        </Link>
      </td>
      <td>{itm.requestsCount}</td>
      <td>{getFormattedDate(new Date(itm.lastDateRequestedUtc))}</td>
    </tr>
  );
};

const ColumnHeader = ({ name, columnId, listResults, width, onClick }) => {
  const style = {};

  if (width) {
    style.width = width;
  }
  return (
    <th
      key={name}
      className="text-left sortable"
      style={style}
      onClick={() => onClick(columnId)}
      onDoubleClick={() => {
        return false;
      }}
    >
      {name}
      {listResults != null && listResults.sortBy == columnId ? (
        <Fragment>
          {listResults.sortDirection == "Ascending" ? (
            <ChevronDownIcon width="10" className="inline-block ml-1" />
          ) : (
            <ChevronUpIcon width="10" className="inline-block ml-1" />
          )}
        </Fragment>
      ) : (
        ""
      )}
    </th>
  );
};

function AccountListScreen() {
  const routeMatch = useRouteMatch();

  const [state, setState] = useState({
    status: "loaded",
    gridStatus: "loaded",
    error: null,
  });

  const [listSettings, setListSettings] = useState({
    sortBy: "Account",
    sortDirection: "Ascending",
    page: 1,
    search: "",
    nextEnabled: false,
    backEnabled: false,
  });

  const [listResults, setListResults] = useState(null);
  const [search, setSearch] = useState("");
  const searchId = useRef(0);
  const firstSearch = useRef(true);
  const searchTimeoutId = useRef(null);

  const loadAccounts = async () => {
    {
      const thisSearchId = searchId.current + 1;
      searchId.current = thisSearchId;

      const query = {
        page: listSettings.page,
        sortBy: listSettings.sortBy,
        sortDirection: listSettings.sortDirection,
        search: listSettings.search,
      };

      const url = `/api/fiaccounts?${toQueryString(query)}`;
      setState((prevState) => ({ ...prevState, gridStatus: "loading" }));
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });
      console.log(
        `SearchId: ${searchId.current}, ThisSearchId: ${thisSearchId}`
      );
      if (searchId.current != thisSearchId) {
        return;
      }
      setState((prevState) => ({ ...prevState, gridStatus: "loaded" }));

      if (response.ok) {
        const data = await response.json();
        const updates = {
          backEnabled: data.pageNumber > 1,
          nextEnabled: data.pageNumber < data.totalPages,
        };

        setListResults({ ...data });
        setListSettings((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await response.text();
        console.log(`Error loading accounts list: ${message}`);

        let updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }
  };

  const handleSort = (sortBy) => {
    const listSettingsState = listSettings;
    if (listSettingsState.sortBy == sortBy) {
      listSettingsState.sortDirection =
        listSettingsState.sortDirection == "Ascending"
          ? "Descending"
          : "Ascending";
    } else {
      listSettingsState.sortDirection = "Ascending";
    }
    listSettingsState.sortBy = sortBy;
    listSettingsState.page = 1;
    loadAccounts();
    setListSettings((prevState) => ({ ...prevState, listSettingsState }));
  };

  const handlePreviousPage = function () {
    if (listSettings.backEnabled) {
      goToPage(listSettings.page - 1);
    }
  };

  const handleNextPage = function () {
    if (listSettings.nextEnabled) {
      goToPage(listSettings.page + 1);
    }
  };

  const goToPage = function (page) {
    listSettings.page = page;
    setListSettings((prevState) => ({ ...prevState, page }));
    loadAccounts();
  };

  const reload = () => {};

  useEffect(() => {
    console.log(state);
  }, [state]);

  useEffect(() => {
    //This gets called on the initial page render. Prevent a query here.
    if (firstSearch.current === false) {
      searchTimeoutId.current = setTimeout(() => {
        const listSettingsState = listSettings;
        listSettingsState.search = search;
        listSettingsState.page = 1;
        setState((prevState) => ({ ...prevState, listSettingsState }));
        loadAccounts();
      }, 500);
    }
    firstSearch.current = false;
    return () => {
      if (searchTimeoutId.current != null) {
        clearTimeout(searchTimeoutId.current);
      }
    };
  }, [search]);

  useEffect(() => {
    loadAccounts();
  }, []);
  return (
    <DocuPointLayout>
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full">
            <div className="flex-1 text-lg ml-4 font-medium">Accounts</div>
            <div className="flex-1 text-right pr-4">
              <input
                type="text"
                placeholder="Search Accounts"
                className="searchBar"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
          </div>
          {listResults != null ? (
            listResults.totalPages > 0 ? (
              <ListResults
                listResults={listResults}
                isLoading={state.gridStatus === "loading"}
                onSort={handleSort}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
                backEnabled={listSettings.backEnabled}
                nextEnabled={listSettings.nextEnabled}
              />
            ) : (
              <div className="text-center mt-32 mb-40 text-gray-400">
                <SearchIcon width="80" className="text-gray-200 mx-auto" />
                No accounts were found matching your search
              </div>
            )
          ) : (
            <div className="text-center mt-32 mb-40 text-gray-400">
              <BeakerIcon width="80" className="text-gray-200 mx-auto" />
              Searching for accounts...
            </div>
          )}
        </>
      )}
    </DocuPointLayout>
  );
}

export default AccountListScreen;
