import React, { Fragment, useEffect, useState } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  Link,
  useRouteMatch,
} from "react-router-dom";
import {
  CancelButton,
  ThemedButton,
} from "../../components/buttons/themedButton";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { postJson } from "../../utils/api-client";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { Validator } from "../../components/validator";
import { RefreshIcon } from "@heroicons/react/solid";
import {
  toastSuccess,
  checkRequiredFields,
  toastError,
} from "../../utils/utils.js";
import { ToggleButton } from "../../components/buttons/toggleButton";
import { UserIcon } from "@heroicons/react/solid";

function AppUserScreen({ appUserKey, returnTo, global }) {
  const history = useHistory();

  const [state, setState] = useState({
    status: "loading",
    error: null,
    validationErrors: {},
    showErrorModal: false,
    modalErrorMessage: null,
    modalWebResponse: null,
    saving: false,
    originalName: "",
    fields: {
      name: "",
      email: "",
      isActive: true,
      tenantAdmin: false,
    },
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = function (event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const fields = state.fields;
    fields[name] = value;
    setStateValue("fields", fields);
  };

  const handleToggleChange = function (field) {
    const fields = state.fields;
    fields[field] = !fields[field];
    setStateValue("fields", fields);
  };

  const validateForm = function () {
    const values = state.fields;
    const validationErrors = {};
    checkRequiredFields(values, ["name", "email"], validationErrors);

    const valid = Object.keys(validationErrors).length == 0;

    setState((prevState) => ({
      ...prevState,
      validationErrors: validationErrors,
    }));

    if (!valid) {
      toastError(
        "The information you entered could not be submitted. Please review the errors listed"
      );
    }

    return valid;
  };

  const handleSave = async function () {
    if (!validateForm()) {
      return;
    }
    const data = state.fields;
    setStateValue("saving", true);
    let url = "/api/appUsers";
    let method = "POST";

    if (appUserKey) {
      url = `${url}/${appUserKey}`;
      method = "PUT";
    }
    const resp = await postJson(url, data, method);
    setStateValue("saving", false);

    if (resp.ok) {
      toastSuccess("Changes saved!");
      history.push(returnTo);
    } else {
      if (resp.status == 400) {
        var errorMessage = await resp.text();
        setStateValue("modalErrorMessage", errorMessage);
      }
      setStateValue("showErrorModal", true);
    }
  };

  const reload = () => {
    window.location.reload();
  };

  const closeErrorModal = () => {
    setState((prevState) => ({
      ...prevState,
      showErrorModal: false,
      modalErrorMessage: null,
    }));
  };

  useEffect(() => {
    async function loadData() {
      const url = `/api/AppUsers/${appUserKey}`;
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (response.ok) {
        const data = await response.json();
        const updates = {
          fields: {
            name: data.name,
            email: data.email,
            isActive: data.isActive,
            tenantAdmin: data.tenantAdmin,
          },
          status: "loaded",
          originalName: data.name,
          originalLogoUrl: data.brandingLogoUrl,
        };

        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await response.text();
        console.log(`Error loading app user data: ${message}`);

        const updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }

    if (appUserKey) {
      loadData();
    } else {
      const updates = {
        status: "loaded",
        originalName: "New User",
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    }
  }, []);

  return (
    <Fragment>
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <form>
            <div className="flex items-center text-l rounded pt-1 mb-1">
              <div className="flex-none">
                <UserIcon className="text-gray-300 w-4 mr-1" />
              </div>
              <div className="flex-none">{state.originalName}</div>
            </div>

            <div className="bg-gray-100 p-4">
              <div className="formSection">
                <div className="formSectionLeft">
                  <div className="px-4 sm:px-0">
                    <h3 className="formSectionHeader">General</h3>
                  </div>
                </div>

                <div className="formSectionRight">
                  <div className="formSectionRightPanel">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="name" className="formLabel">
                          Name
                        </label>
                        <input
                          type="text"
                          value={state.fields.name}
                          name="name"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="name"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="emailPort" className="formLabel">
                          Email Address
                        </label>
                        <input
                          type="text"
                          value={state.fields.email}
                          name="email"
                          onChange={handleInputChange}
                        />
                        <Validator
                          fieldName="email"
                          errorsList={state.validationErrors}
                        ></Validator>
                      </div>
                      <div className="col-span-3 sm:col-span-2">
                        <label htmlFor="isActive" className="formLabel">
                          Active
                        </label>
                        <ToggleButton
                          checked={state.fields.isActive}
                          onChange={(e) => {
                            handleToggleChange("isActive");
                          }}
                        ></ToggleButton>
                      </div>
                      {!global && (
                        <div className="col-span-3 sm:col-span-2">
                          <label htmlFor="tenantAdmin" className="formLabel">
                            Tenant Administrator
                          </label>
                          <ToggleButton
                            checked={state.fields.tenantAdmin}
                            onChange={(e) => {
                              handleToggleChange("tenantAdmin");
                            }}
                          ></ToggleButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="mt-4">
            <div className="inline-block w-40">
              <ThemedButton onClick={handleSave} disabled={state.saving}>
                Save
              </ThemedButton>
            </div>
            <div className="inline-block w-40 ml-2">
              <Link to={returnTo}>
                <CancelButton>Cancel</CancelButton>
              </Link>
            </div>
          </div>
        </div>
      )}
      <ErrorModal
        show={state.showErrorModal}
        errorMessage={state.modalErrorMessage}
        onClose={closeErrorModal}
      ></ErrorModal>
    </Fragment>
  );
}

export { AppUserScreen };
