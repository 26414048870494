import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BrowserRouter as Router, Link, useRouteMatch } from "react-router-dom";
import { RequestsNavigationPanel } from "./requestsNavigationPanel";
import { ThemedButton } from "../../components/buttons/themedButton";
import { ItemActions } from "../../components/buttons/itemActions";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  RefreshIcon,
  SearchIcon,
  BeakerIcon,
  DownloadIcon,
  CheckIcon,
  MailIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/solid";
import styles from "./index.module.css";
import { DocuPointLayout } from "../../components/docupointLayout";
import { getFormattedDate, toQueryString } from "../../utils/utils";
import { postDownloadRequest } from "../../utils/api-client";
import { SegmentedProgressMeter } from "../../components/segmentedProgressMeter";
import {
  OpenRequests,
  RequestWithSubmissions,
  ClosedRequests,
  RequestTabContext,
} from "./requestsIndex";
import dayjs from "dayjs";

const ListResults = ({
  listResults,
  isLoading,
  backEnabled,
  nextEnabled,
  onSort,
  onPreviousPage,
  onNextPage,
}) => {
  return (
    <div>
      <table className={`dataTable w-full mt-4 ${isLoading ? "loading" : ""}`}>
        <thead>
          <tr>
            <ColumnHeader
              name="Name"
              columnId="Name"
              listResults={listResults}
              onClick={onSort}
            />
            <ColumnHeader
              name="Recipient"
              columnId="RecipientName"
              listResults={listResults}
              onClick={onSort}
            />
            <ColumnHeader
              name="Requested"
              columnId="DateCreatedUtc"
              listResults={listResults}
              onClick={onSort}
            />
            <ColumnHeader
              name="Progress"
              columnId="LastSubmissionDate"
              listResults={listResults}
              onClick={onSort}
              width="230px"
            />
            <th width="16"></th>
          </tr>
        </thead>
        <tbody>
          {listResults.pageData.map((itm, idx) => (
            <RequestItem itm={itm} key={`request_${idx}`} />
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <div className="pageCounts">
          {listResults.pageStart}-{listResults.pageEnd} of{" "}
          {listResults.totalCount}
        </div>
        <span
          className={`pageDirection ${backEnabled === false ? "disabled" : ""}`}
          onClick={onPreviousPage}
        >
          <ChevronLeftIcon />
        </span>
        <span
          className={`pageDirection ${nextEnabled === false ? "disabled" : ""}`}
          onClick={onNextPage}
        >
          <ChevronRightIcon />
        </span>
      </div>
    </div>
  );
};

const RequestItem = ({ itm, idx }) => {
  const handleDownloadDocuments = () => {
    postDownloadRequest(`/api/customerrequests/${itm.key}/download`);
  };

  return (
    <tr className="text-sm">
      <td>
        <div>
          <Link className="font-medium colored" to={`/requests/${itm.key}`}>
            {itm.requestName}
          </Link>
        </div>
        <div className="subtext">
          <Link to={`/requests/${itm.key}`}>{itm.account}</Link>
        </div>
      </td>
      <td>{itm.recipientName}</td>
      <td>
        <div>{getFormattedDate(new Date(itm.dateCreatedUtc))}</div>
        <div className="subtext">{itm.createdByName}</div>
      </td>
      <td>
        <div>
          <SegmentedProgressMeter
            segments="10"
            percentFilled={itm.submittedDocRequestsPercent}
          />
        </div>
        <div className="subtext">
          {!!itm.lastSubmissionDate &&
            `Last Submission: ${dayjs(itm.lastSubmissionDate).format(
              "M/D/YYYY"
            )}`}
        </div>
      </td>
      <td>
        <ItemActions
          actions={[
            {
              name: "Download Documents",
              execute: handleDownloadDocuments,
              disabled: itm.submittedDocRequestsCount == 0,
            },
          ]}
        />
      </td>
    </tr>
  );
};

const ColumnHeader = ({ name, columnId, listResults, width, onClick }) => {
  const style = {};

  if (width) {
    style.width = width;
  }
  return (
    <th
      key={name}
      className="text-left sortable"
      style={style}
      onClick={() => onClick(columnId)}
      onDoubleClick={() => {
        return false;
      }}
    >
      {name}
      {listResults != null && listResults.sortBy == columnId ? (
        <Fragment>
          {listResults.sortDirection == "Ascending" ? (
            <ChevronDownIcon width="10" className="inline-block ml-1" />
          ) : (
            <ChevronUpIcon width="10" className="inline-block ml-1" />
          )}
        </Fragment>
      ) : (
        ""
      )}
    </th>
  );
};

function RequestListScreen({ setRequestsTab }) {
  const routeMatch = useRouteMatch();
  const requestFilter = useContext(RequestTabContext);

  const [state, setState] = useState({
    status: "loaded",
    gridStatus: "loaded",
    error: null,
  });

  const [listSettings, setListSettings] = useState({
    sortBy: "DateCreatedUtc",
    sortDirection: "Descending",
    page: 1,
    search: "",
    filterCategory: "MyRequests",
    nextEnabled: false,
    backEnabled: false,
  });

  const [listResults, setListResults] = useState(null);
  const [search, setSearch] = useState("");
  const searchId = useRef(0);
  const firstSearch = useRef(true);
  const searchTimeoutId = useRef(null);

  const loadRequests = async () => {
    {
      const thisSearchId = searchId.current + 1;
      searchId.current = thisSearchId;

      const query = {
        page: listSettings.page,
        sortBy: listSettings.sortBy,
        sortDirection: listSettings.sortDirection,
        search: listSettings.search,
        myRequests: listSettings.filterCategory == "MyRequests",
        filter: requestFilter.key,
      };

      const url = `/api/customerrequests?${toQueryString(query)}`;
      setState((prevState) => ({ ...prevState, gridStatus: "loading" }));
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });
      console.log(
        `SearchId: ${searchId.current}, ThisSearchId: ${thisSearchId}`
      );
      if (searchId.current != thisSearchId) {
        return;
      }
      setState((prevState) => ({ ...prevState, gridStatus: "loaded" }));

      if (response.ok) {
        const data = await response.json();
        const updates = {
          backEnabled: data.pageNumber > 1,
          nextEnabled: data.pageNumber < data.totalPages,
        };

        setListResults({ ...data });
        setListSettings((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await response.text();
        console.log(`Error loading app users list: ${message}`);

        let updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }
  };

  const handleSort = (sortBy) => {
    const listSettingsState = listSettings;
    if (listSettingsState.sortBy == sortBy) {
      listSettingsState.sortDirection =
        listSettingsState.sortDirection == "Ascending"
          ? "Descending"
          : "Ascending";
    } else {
      listSettingsState.sortDirection = "Ascending";
    }
    listSettingsState.sortBy = sortBy;
    listSettingsState.page = 1;
    loadRequests();
    setListSettings((prevState) => ({ ...prevState, listSettingsState }));
  };

  const handlePreviousPage = function () {
    if (listSettings.backEnabled) {
      goToPage(listSettings.page - 1);
    }
  };

  const handleNextPage = function () {
    if (listSettings.nextEnabled) {
      goToPage(listSettings.page + 1);
    }
  };

  const goToPage = function (page) {
    listSettings.page = page;
    setListSettings((prevState) => ({ ...prevState, page }));
    loadRequests();
  };

  const reload = () => {};

  const onApplyFilter = (newFilter) => {
    setRequestsTab(newFilter);
  };

  const handleFilterCategoryChange = function (e) {
    const filterCategory = e.target.value;
    listSettings.filterCategory = filterCategory;
    setListSettings((prevState) => ({
      ...prevState,
      filterCategory: filterCategory,
    }));
    loadRequests();
  };

  useEffect(() => {
    const listSettingsState = listSettings;
    listSettingsState.page = 1;

    if (requestFilter.name == "Recent Submissions") {
      listSettingsState.sortBy = "LastSubmissionDate";
      listSettingsState.sortDirection = "Descending";
    } else {
      listSettingsState.sortBy = "DateCreatedUtc";
      listSettingsState.sortDirection = "Ascending";
    }
    setListSettings((prevState) => ({ ...prevState, listSettingsState }));
    loadRequests();
  }, [requestFilter]);

  useEffect(() => {
    console.log(state);
  }, [state]);

  useEffect(() => {
    //This gets called on the initial page render. Prevent a query here.
    if (firstSearch.current === false) {
      searchTimeoutId.current = setTimeout(() => {
        const listSettingsState = listSettings;
        listSettingsState.search = search;
        listSettingsState.page = 1;
        setState((prevState) => ({ ...prevState, listSettingsState }));
        loadRequests();
      }, 500);
    }
    firstSearch.current = false;
    return () => {
      if (searchTimeoutId.current != null) {
        clearTimeout(searchTimeoutId.current);
      }
    };
  }, [search]);

  useEffect(() => {
    loadRequests();
  }, []);
  return (
    <DocuPointLayout backgroundColor="bg-gray-100">
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="table w-full">
          <div className={`table-cell w-64 navigationPanel pr-4 align-top`}>
            <div className="text-sm">
              <div className="font-medium mb-3 ml-4 text-gray-600">
                REQUESTS
              </div>
              <div
                className={`link ${
                  requestFilter.key === OpenRequests.key ? "selected" : ""
                }`}
              >
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => onApplyFilter(OpenRequests)}
                >
                  <PaperAirplaneIcon className="w-5 mr-1 inline-block text-gray-300" />
                  <span>Open Requests</span>
                </div>
              </div>
              <div
                className={`link ${
                  requestFilter.key === RequestWithSubmissions.key
                    ? "selected"
                    : ""
                }`}
              >
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => onApplyFilter(RequestWithSubmissions)}
                >
                  <MailIcon className="w-5 mr-1 inline-block text-gray-300" />
                  <span>Recent Submissions</span>
                </div>
              </div>
              <div
                className={`link ${
                  requestFilter.key === ClosedRequests.key ? "selected" : ""
                }`}
              >
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => onApplyFilter(ClosedRequests)}
                >
                  <CheckIcon className="w-5 mr-1 inline-block text-gray-300" />
                  <span>Closed Requests</span>
                </div>
              </div>
            </div>
          </div>
          <div className="table-cell bg-white border rounded-md align-top">
            <div className="text-lg font-medium m-4">{requestFilter.name}</div>
            <div className="flex w-full">
              <div className="pl-4">
                <select
                  value={listSettings.filterCategory}
                  onChange={handleFilterCategoryChange}
                >
                  <option value="All">All Requests</option>
                  <option value="MyRequests">My Requests</option>
                </select>
              </div>
              <div className="flex-1 text-right pr-4">
                <input
                  type="text"
                  placeholder="Search Requests"
                  className="searchBar"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </div>
            </div>
            {listResults != null ? (
              listResults.totalPages > 0 ? (
                <ListResults
                  listResults={listResults}
                  isLoading={state.gridStatus === "loading"}
                  onSort={handleSort}
                  onPreviousPage={handlePreviousPage}
                  onNextPage={handleNextPage}
                  backEnabled={listSettings.backEnabled}
                  nextEnabled={listSettings.nextEnabled}
                />
              ) : (
                <div className="text-center mt-32 mb-40 text-gray-400">
                  <SearchIcon width="80" className="text-gray-200 mx-auto" />
                  No requests were found matching your search
                </div>
              )
            ) : (
              <div className="text-center mt-32 mb-40 text-gray-400">
                <BeakerIcon width="80" className="text-gray-200 mx-auto" />
                Searching for requests...
              </div>
            )}
          </div>
        </div>
      )}
    </DocuPointLayout>
  );
}

export default RequestListScreen;
