import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { getLoginDetails, getProfile, saveLoginDetails } from "../utils/utils";

const TenantRoute = ({ children, ...rest }) => {
  const [ready, setReady] = useState(false);
  const [tenantInfo, setTenantInfo] = useState(getLoginDetails().tenantInfo);
  useEffect(() => {
    const loadData = async () => {
      const { tenantInfo } = await getProfile();
      if (tenantInfo) {
        const loginDetails = getLoginDetails();
        loginDetails.tenantInfo = tenantInfo;
        saveLoginDetails(loginDetails);
        setTenantInfo(tenantInfo);
      }
      setReady(true);
    };

    if (!tenantInfo) {
      loadData();
    } else {
      setReady(true);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={() => {
        if (ready) {
          return tenantInfo ? children : <Redirect to={"/tenants"} />;
        } else {
          return "";
        }
      }}
    />
  );
};

export { TenantRoute };
