import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import {
  CancelButton,
  ThemedButton,
} from "../../components/buttons/themedButton";
import { authInfo } from "../../components/authInfo";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { RefreshIcon, PlusIcon } from "@heroicons/react/solid";
import componentStyles from "../../components/index.module.css";
import { Validator } from "../../components/validator";
import { DocuPointLayout } from "../../components/docupointLayout";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { postJson } from "../../utils/api-client";
import { clearProfile, toastSuccess } from "../../utils/utils";

function ProfileScreen() {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const baseUrl = routeMatch.url.endsWith("/")
    ? routeMatch.url.substring(0, routeMatch.url.length - 1)
    : routeMatch.url;
  const [state, setState] = useState({
    status: "loading",
    error: null,
    validationErrors: {},
    showErrorModal: false,
    fields: {
      name: "",
      email: "",
      submittedDocumentsNotificationsSetting: "",
      defaultRequestTitle: "",
      defaultRequestDescription: "",
    },
    canChangePassword: false,
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = function (event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const fields = state.fields;
    fields[name] = value;
    setStateValue("fields", fields);
  };

  const handleToggleChange = function (field) {
    const fields = state.fields;
    fields[field] = !fields[field];
    setStateValue("fields", fields);
  };

  const handleSave = async function (event) {
    const data = state.fields;
    setStateValue("saving", true);

    const resp = await postJson("/api/profile", data, "PUT");
    setStateValue("saving", false);

    if (resp.ok) {
      clearProfile();
      toastSuccess("Changes saved!");
    } else {
      setStateValue("showErrorModal", true);
    }
  };

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    async function loadData() {
      const url = `/api/profile`;
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (response.ok) {
        const data = await response.json();
        if (!data.submittedDocumentsNotificationsSetting) {
          data.submittedDocumentsNotificationsSetting = "None";
        }
        const updates = {
          fields: data,
          status: "loaded",
          canChangePassword: data.canChangePassword,
        };

        setState((prevState) => ({ ...prevState, ...updates }));
      } else {
        const message = await response.text();
        console.log(`Error loading tenant data: ${message}`);

        const updates = {
          status: "error",
          error: new Error(
            "We're sorry. There seems to be an issue loading this page."
          ),
        };
        setState((prevState) => ({ ...prevState, ...updates }));
      }
    }

    loadData();
  }, []);

  return (
    <DocuPointLayout backgroundColor="bg-gray-100">
      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <form>
            <div className="formSection">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Basic Information</h3>
                  <p className="formSectionHeaderDescription">
                    Tell us a little bit about yourself
                  </p>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="account" className="formLabel">
                        Name
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <span>{state.fields.name}</span>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="account" className="formLabel">
                        Email Address
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <span>{state.fields.email}</span>
                      </div>
                    </div>
                    {state.canChangePassword && (
                      <div className="col-span-3 sm:col-span-2">
                        <Link to="/setPassword">
                          <ThemedButton fitted={true}>
                            Change Password
                          </ThemedButton>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="formSection mt-4">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Notifications</h3>
                  <p className="formSectionHeaderDescription">
                    When do you want us to notify you?
                  </p>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-1 gap-6">
                    <div className="">
                      <div>
                        <label htmlFor="account" className="formLabel">
                          Submitted Documents
                        </label>
                        <div>
                          <input
                            type="radio"
                            checked={
                              state.fields
                                .submittedDocumentsNotificationsSetting ==
                              "None"
                            }
                            id="submittedDocumentsNotificationsSetting_None"
                            name="submittedDocumentsNotificationsSetting"
                            className="mr-1"
                            value="None"
                            onChange={handleInputChange}
                          />
                          <label for="submittedDocumentsNotificationsSetting_None">
                            Do not notify me
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              state.fields
                                .submittedDocumentsNotificationsSetting ==
                              "MyRequests"
                            }
                            id="submittedDocumentsNotificationsSetting_MyRequests"
                            name="submittedDocumentsNotificationsSetting"
                            className="mr-1"
                            value="MyRequests"
                            onChange={handleInputChange}
                          />
                          <label for="submittedDocumentsNotificationsSetting_MyRequests">
                            Notify me when documents are submitted for requests
                            I created
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              state.fields
                                .submittedDocumentsNotificationsSetting ==
                              "AllRequests"
                            }
                            id="submittedDocumentsNotificationsSetting_All"
                            name="submittedDocumentsNotificationsSetting"
                            className="mr-1"
                            value="AllRequests"
                            onChange={handleInputChange}
                          />
                          <label for="submittedDocumentsNotificationsSetting_AllRequests">
                            Notify me when documents are submitted for any
                            request
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="formSection mt-4">
              <div className="formSectionLeft">
                <div className="px-4 sm:px-0">
                  <h3 className="formSectionHeader">Defaults</h3>
                  <p className="formSectionHeaderDescription">
                    Your default settings when you request documents
                  </p>
                </div>
              </div>
              <div className="formSectionRight">
                <div className="formSectionRightPanel">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="defaultRequestTitle"
                        className="formLabel"
                      >
                        Request Title
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          value={state.fields.defaultRequestTitle}
                          name="defaultRequestTitle"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="defaultDescription" className="formLabel">
                        Description
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          type="text"
                          value={state.fields.defaultRequestDescription}
                          name="defaultRequestDescription"
                          onChange={handleInputChange}
                          className="w-full"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="mt-4">
            <div className="inline-block w-40">
              <ThemedButton onClick={handleSave}>Save</ThemedButton>
            </div>
            <div className="inline-block w-40 ml-2">
              <Link to="/requests">
                <CancelButton>Cancel</CancelButton>
              </Link>
            </div>
          </div>
        </div>
      )}

      <ErrorModal
        show={state.showErrorModal}
        onClose={() => setStateValue("showErrorModal", false)}
      ></ErrorModal>
    </DocuPointLayout>
  );
}

export { ProfileScreen };
