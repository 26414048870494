import React from "react";
import { ThemedButton } from "../../components/buttons/themedButton";
import { Paragraphs } from "../../components/paragraphs";
import { getLoginDetails } from "../../utils/utils";
import { useHistory } from "react-router-dom";

function ThankYouScreen() {
  const { tenantInfo } = getLoginDetails();
  const history = useHistory();

  const handleAddMoreDocuments = () => {
    history.push("/account");
  };

  return (
    <div className="pt-8 flex flex-col items-center sm:pt-0">
      <div className="text-4xl font-extrabold">Thank You!</div>
      <div className="flex flex-col text-md text-gray-500 leading-relaxed px-4 my-8 align-middle justify-center sm:px-16">
        <Paragraphs text={tenantInfo.thankYouText} />
      </div>
      <ThemedButton onClick={handleAddMoreDocuments} fitted={true}>
        Add More Documents
      </ThemedButton>
    </div>
  );
}

export { ThankYouScreen };
